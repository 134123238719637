import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import coinsImg from "../assets/img/dashboard/coins.png";
import { makeStyles } from "@material-ui/core/styles";
import UserContext from "../contexts/UserContext";
import get from "lodash.get";
import { s3Bucket } from "../appConfig";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import { formatNumber as fN } from "../utils/functions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-start",
  },
  image: {
    width: 80,
    height: 80,
    [theme.breakpoints.down("sm")]: {
      height: 50,
      width: 50,
    },
  },
  coinsImage: {
    width: 35,
    height: 35,
    [theme.breakpoints.down("lg")]: {
      height: 25,
      width: 25,
    },
  },
  link: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginRight: 30,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    color: "#EF4137",
    fontWeight: 700,
  },
}));

function NavIcons() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const profilePic = user.profilePic;
  const profilePicPath = profilePic.match(/^http/)
    ? profilePic
    : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
  const walletBalance = user.wallet.balance;
  let handle = user.cashtag;
  handle = /^\$/.test(handle) ? handle : `$${handle}`;
  return (
    <div className={classes.root}>
      <NavLink to="/smart-profile">
        <Avatar alt={handle} src={profilePicPath} className={classes.image}></Avatar>
      </NavLink>
      <Hidden xsDown>
        <div className={classes.link}>
          <div className={classes.container}>
            <img className={classes.coinsImage} src={coinsImg} />

            <Typography variant="subtitle2" className={classes.label}>
              {walletBalance}
            </Typography>
            <Typography variant="subtitle2" className={classes.label}>
              CA$H
            </Typography>
          </div>
        </div>
      </Hidden>
    </div>
  );
}

export default NavIcons;
