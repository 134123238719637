import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import get from "lodash.get";
import { s3Bucket } from "../../appConfig";
import { flureeFetch } from "../../flureeFetch";

import NavIcons from "../NavIcons";

class Wallet extends Component {
  state = {
    socialCashAmt: 1500,
  };

  componentDidMount() {
    const profilePic = this.props.user.profilePic;
    const profilePicPath = profilePic.match(/^http/)
      ? profilePic
      : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
    this.setState({ profilePic: profilePicPath });
    const subpage = this.props.match.params.subpage;
    debugger;
    const walletName = this.props.user.wallet.name;
    const blockOneDate = this.props.blockData.instant;
    const nowDate = new Date();
    const dayAgoDate = new Date().setDate(nowDate.getDate() - 1);
    const weekAgoDate = new Date().setDate(nowDate.getDate() - 7);
    const monthAgoDate = new Date().setMonth(nowDate.getMonth() - 1);
    this.getWalletStats(walletName, blockOneDate, dayAgoDate, weekAgoDate, monthAgoDate).then(
      (res) => this.setState({ socialCashAmt: this.state.balance })
    );
  }

  getWalletStats(walletName, blockOneDate, dayAgoDate, weekAgoDate, monthAgoDate) {
    const dayFlag = blockOneDate < dayAgoDate;
    const weekFlag = blockOneDate < weekAgoDate;
    const monthFlag = blockOneDate < monthAgoDate;
    const selectOne = ["?earnings", "?balance"];
    const where = [
      ["?wallet", "wallet/name", walletName],
      ["?wallet", "wallet/earnings", "?earnings"],
      ["?wallet", "wallet/balance", "?balance"],
    ];
    const firstWalletDate = new Date(blockOneDate + 1000000).toISOString();
    if (dayFlag) {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdbP1D",
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsYesterday");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsYesterday",
        { optional: true },
      ]);
    }
    if (weekFlag) {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdbP1W",
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsLastWeek");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastWeek",
        { optional: true },
      ]);
    }
    if (monthFlag) {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdbP1M",
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true },
      ]);
    } else {
      selectOne.push("?earningsLastMonth");
      where.push([
        "$fdb" + firstWalletDate,
        "?wallet",
        "wallet/earnings",
        "?earningsLastMonth",
        { optional: true },
      ]);
    }
    const walletQuery = {
      selectOne: selectOne,
      where: where,
    };
    return flureeFetch("/query", walletQuery).then((res) => {
      if (res) {
        const [earnings, balance, earningsYesterday, earningsLastWeek, earningsLastMonth] = res;
        const dayEarnings = earnings - (earningsYesterday || 0);
        const weekEarnings = earnings - (earningsLastWeek || 0);
        const monthEarnings = earnings - (earningsLastMonth || 0);
        this.setState({
          dayEarnings: dayEarnings,
          weekEarnings: weekEarnings,
          monthEarnings: monthEarnings,
          balance: balance,
        });
      }
    });
  }

  // getWalletStats(walletName) {
  //     const walletQuery = {
  //         "selectOne": ["?earnings", "?balance", "?earningsYesterday", "?earningsLastWeek", "?earningsLastMonth", "?earningsLastYear"],
  //         "where":
  //             [["?wallet", "wallet/name", walletName],
  //             ["?wallet", "wallet/earnings", "?earnings"],
  //             ["?wallet", "wallet/balance", "?balance"],
  //             ["$fdb9", "?wallet", "wallet/earnings", "?earningsYesterday", { "optional": true }],
  //             ["$fdb8", "?wallet", "wallet/earnings", "?earningsLastWeek", { "optional": true }],
  //             ["$fdb6", "?wallet", "wallet/earnings", "?earningsLastMonth", { "optional": true }],
  //             ["$fdb5", "?wallet", "wallet/earnings", "?earningsLastYear", { "optional": true }]
  //             ]
  //     }

  //     return flureeFetch('/query', walletQuery)
  //         .then(res => {
  //             const [earnings, balance, earningsYesterday, earningsLastWeek, earningsLastMonth, earningsLastYear] = res
  //             const dayEarnings = earnings - earningsYesterday;
  //             const weekEarnings = earnings - earningsLastWeek;
  //             const monthEarnings = earnings - earningsLastMonth;
  //             const yearEarnings = earnings - earningsLastYear;

  //             this.setState({
  //                 dayEarnings: dayEarnings, weekEarnings: weekEarnings, monthEarnings: monthEarnings,
  //                 yearEarnings: yearEarnings, balance: balance
  //             })
  //         })
  // }

  render() {
    const { dayEarnings, weekEarnings, monthEarnings, yearEarnings, balance } = this.state;

    return (
      <>
        {/* main dashboard content */}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              maxHeight: 500,
              minHeight: "50vh",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontFamily: "PT Sans",
                fontSize: 60,
                lineHeight: "78px",
                letterSpacing: 0,
                color: "#4096D2",
              }}
            >
              REDEEM BALANCE
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Open Sans",
                fontSize: 30,
                lineHeight: "41px",
                letterSpacing: 0,
                color: "#282560",
              }}
            >
              Every 100 CA$H Tokens are redeemable for $1 USD!
            </p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: 324,
                  height: 153,
                  border: "4px solid #282560",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Open Sans",
                    fontSize: 30,
                    lineHeight: "41px",
                    letterSpacing: 0,
                    color: "#282560",
                  }}
                >
                  Available Balance
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Open Sans",
                    fontSize: 30,
                    lineHeight: "41px",
                    letterSpacing: 0,
                    color: "#EF4137",
                    fontWeight: 700,
                  }}
                >
                  {balance ? balance : "--"} CA$H
                </p>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "PT Sans",
                  fontSize: 60,
                  lineHeight: "78px",
                  letterSpacing: 0,
                  color: "#4096D2",
                }}
              >
                =
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  width: 324,
                  height: 153,
                  border: "4px solid #282560",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Open Sans",
                    fontSize: 30,
                    lineHeight: "41px",
                    letterSpacing: 0,
                    color: "#282560",
                  }}
                >
                  USD Value
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Open Sans",
                    fontSize: 30,
                    lineHeight: "41px",
                    letterSpacing: 0,
                    color: "#EF4137",
                    fontWeight: 700,
                  }}
                >
                  ${balance ? balance / 100 : "--"} USD
                </p>
              </div>
            </div>
            <p style={{ textAlign: "center" }}>
              This is a beta feature: Purchasing options available October 31st
            </p>
          </div>
        </div>

        {/* small nav buttons */}
        <div style={{ position: "absolute", top: 20, right: 20, zIndex: 100 }}>
          <NavIcons user={this.props.user} imgSrc={this.state.profilePic} balance={balance} />
        </div>
      </>
    );
    return (
      <div className="row">
        <div className="col-sm-2" />
        <div className="col-sm-8">
          <div className="wallet" style={{ marginTop: "20px" }}>
            <div style={{ marginBottom: "20px" }}>
              <span>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    display: "inline-block",
                  }}
                >
                  Earnings
                </h4>
                &nbsp;&nbsp;
                <span style={{ fontSize: "16px" }}>Social Cash (SC$H)</span>
              </span>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>Day</td>
                    <td>{Number(dayEarnings).toLocaleString()} SC$H</td>
                  </tr>
                  <tr>
                    <td>Week</td>
                    <td>{Number(weekEarnings).toLocaleString()} SC$H</td>
                  </tr>
                  <tr>
                    <td>Year-to-Date</td>
                    <td>{Number(yearEarnings).toLocaleString()} SC$H</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <span>
                <h4
                  style={{
                    fontWeight: 700,
                    fontSize: "20px",
                    display: "inline-block",
                  }}
                >
                  Wallet Balance
                </h4>
                &nbsp;&nbsp;
                <span style={{ fontSize: "16px" }}>Social Cash (SC$H)</span>
              </span>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>Current Balance</td>
                    <td>
                      <strong>{Number(balance).toLocaleString()} SC$H</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="redeem">
              <div className="row">
                <div className="col-sm-3">
                  <h3 style={{ fontWeight: 700 }}>Redeem</h3>
                </div>
                <div className="col-sm-4" style={{ lineHeight: "60px", fontSize: "15px" }}>
                  Social Cash (SC$H)
                </div>
                <div className="col-sm-5" style={{ lineHeight: "60px", fontSize: "15px" }}>
                  100 SC$H = 1.00 USD
                </div>
              </div>
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-sm-5" style={{ paddingRight: "0px" }}>
                  <div className="convert-input">
                    <input
                      type="number"
                      value={this.state.socialCashAmt}
                      onChange={(e) => this.setState({ socialCashAmt: e.target.value })}
                    ></input>
                    <span>SC$H</span>
                  </div>
                </div>
                <div className="col-sm-1">
                  <p style={{ fontSize: "20px" }}>=</p>
                </div>
                <div className="col-sm-5" style={{ paddingRight: "0px" }}>
                  <div className="convert-input">
                    <input type="number" value={this.state.socialCashAmt / 100} readOnly></input>
                    <span>USD</span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ minHeight: "75px" }}>
                <div className="col-sm-5">
                  <div className="redeem-button" style={{ borderRadius: "5px" }}>
                    <p className="brand-alt-text">Redeem SC$H to Debit Card</p>
                  </div>
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-5">
                  <div className="redeem-button" style={{ borderRadius: "5px" }}>
                    <p className="brand-alt-text">Redeem SC$H for Products/Services</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-2" />
      </div>
    );
  }
}

export default Wallet;
