import fetch from "isomorphic-fetch";
import { serverURL } from "./appConfig";
import { trackPromise } from "react-promise-tracker";

function isDevelopment() {
  return process.env.NODE_ENV === "development";
}

function parseJSON(response) {
  return response.json().then(function (json) {
    const newResponse = Object.assign(response, { json });
    if (response.status < 300) {
      return newResponse;
    } else {
      throw newResponse.json;
    }
  });
}

// function newToken() {
//   return trackPromise(
//     fetch(`${serverURL}get-fluree-token`, { method: "GET" })
//       .then(parseJSON)
//       .then((res) => {
//         let token = res.json.token;
//         localStorage.setItem("fabricToken", token);
//         // debugger;
//         return token;
//       })
//       .catch((err) => {
//         // debugger;
//         let errorMessage = (err.json && err.json.message) || err;
//         throw new Error(errorMessage);
//       })
//   );
// }

function getToken() {
  const authToken = window.localStorage.getItem("stringBean");
  return authToken;
}

function fetchResp(fullUri, fetchOpts, isDev) {
  return trackPromise(
    fetch(fullUri, fetchOpts)
      .then(parseJSON)
      .then((resp) => resp.json)
      .catch((err) => {
        const x = fullUri;
        debugger;
        if (!err) {
          throw new Error("Error");
        }
        let errorMessage = err.message || (err.json && err.json.message) || err.json || err;
        throw new Error(errorMessage);
      })
  );
}

var flureeFetch = (uri, body = undefined) => {
  const isDev = isDevelopment();
  console.log("isDev", isDev);
  // const gateway = isDev ? "http://localhost:58090/fluree" : "http://localhost:58090/fluree";
  const gateway = isDev ? "https://data.flur.ee/fluree" : "https://data.flur.ee/fluree";
  // const gateway = isDev ? "http://3.132.75.230:8090/fluree" : "http://3.132.75.230:8090/fluree";
  // const gateway = isDev
  //   ? "https://data.flur.ee/fluree"
  //   : "https://data.flur.ee/fluree";
  // USE THIS NEXT ONE FOR PRODUCTION
  const API_KEY =
    "N-WMTrzJmWdYI_oy-h0WPuy7qCHqfbZPUl6_snesmaTFbcPuD-Avqbs6rS9Cyc77_eB3_4lZnE-SI5rY3YKFkw";
  // const API_KEY =
  //   "JurcpstleUXdXf0OHzEOBMyjmH83JG-zL2z7dXFdMGwPLYnAmOilRhqpMhJihH2N4UTMDiTcK8hIOA1unBmpuA";
  // const ledgerId = "fluree-jld/387028092978086";
  // USE THIS NEXT ONE FOR PRODUCTION
  const ledgerId = "fluree-jld/387028092978279";
  // const ledgerId = "fluree-jld/387028092978348";
  // const gateway = `${serverURL}ledger`;
  let fullUri = gateway + uri;

  const fetchOpts = {
    method: "POST",
    headers: { "Content-Type": "application/json", Authorization: API_KEY },
  };
  if (body) {
    if (uri === "/query" || uri === "/history") {
      body.from = ledgerId;
    } else if (uri === "/transact") {
      body.ledger = ledgerId;
    }
  }
  if (body) fetchOpts.body = JSON.stringify(body);

  return trackPromise(
    new Promise((resolve, reject) => {
      // if (!isDev) {
      //   // getToken()
      //   //   .then(res => (fetchOpts.headers["Authorization"] = `Bearer ${res}`))
      //   // .then(res =>
      //   fetchResp(fullUri, fetchOpts)
      //     // )
      //     .then((res) => {
      //       debugger;
      //       // console.log(res);
      //       if (Object.keys(res).includes("result")) {
      //         return resolve(res.result);
      //       } else {
      //         return resolve(res);
      //       }
      //     })
      //     .catch((err) => {
      //       debugger;
      //       reject(err);
      //     });
      // } else {
      fetchResp(fullUri, fetchOpts)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
      // }
    })
  );
};

export { flureeFetch, parseJSON, getToken };
