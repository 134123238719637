import React, { Component } from "react";
import get from "lodash.get";
import { Form, Row, Button } from "react-bootstrap";
import moment from "moment";
import { s3Bucket, serverURL } from "../appConfig";
import TwitterLogin from "react-twitter-auth/lib/react-twitter-auth-component.js";
import { withStyles } from "@material-ui/core/styles";
import ReactCrop from "react-image-crop";
import * as loadImage from "blueimp-load-image";

import CheckMark from "../assets/img/StepOne/checkMark.png";
import Upload from "../assets/img/StepOne/upload-button.png";
import twitterImg from "../assets/img/dashboard/twitter.png";
import { flureeFetch } from "../flureeFetch";
import Font from "../constants/FontSize";
import { Grid, Typography } from "@material-ui/core";
import { trackPromise } from "react-promise-tracker";
import clsx from "clsx";
import constants from "../utils/constants";
const { DEFAULT_CONTEXT, PROPERTY_MAP, CLASS_TYPES } = constants;

const useStyles = (theme) => ({
  formRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginLeft: 10,
    marginRight: 10,
  },
  formControl: {
    borderWidth: "3px !important",
    borderColor: "#282560",
  },
  formLabel: {
    // fontWeight: 40,
    // color: '#282560'
  },
  formContent: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minWidth: 200,
    [theme.breakpoints.up("sm")]: {
      marginRight: 20,
      justifyContent: "space-between",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginTop: 20,
    },
  },
  button: {
    maxWidth: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    border: "none",
    borderRadius: 30,
    border: `3px solid #282560`,
    backgroundColor: "#282560",
    font: "normal normal bold 16px/21px Futura",
    letterSpacing: 0,
    color: "white",
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "transparent",
      color: "#282560",
    },
  },
  buttonLabel: {
    // fontSize: 14,
    font: "normal normal bold 16px/21px Futura",
    letterSpacing: 0,
    color: "white",
    margin: theme.spacing(2),
  },
  saveChanges: {
    position: "static",
    bottom: 0,
    right: 0,
    [theme.breakpoints.up("lg")]: {
      position: "absolute",
      bottom: 15,
      right: "1%",
    },
  },
  changeSaved: {
    background: "#009444 0% 0% no-repeat padding-box",
  },
  twitterBox: {
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 15,
    },
  },
});

function dateToInstant(date) {
  return moment(date, "YYYY-MM-DD").valueOf();
}

const monthArray = [
  { name: "Jan", val: "01", days: 31 },
  { name: "Feb", val: "02", days: 28 },
  { name: "Mar", val: "03", days: 31 },
  { name: "Apr", val: "04", days: 30 },
  { name: "May", val: "05", days: 31 },
  { name: "Jun", val: "06", days: 30 },
  { name: "Jul", val: "07", days: 31 },
  { name: "Aug", val: "08", days: 31 },
  { name: "Sep", val: "09", days: 30 },
  { name: "Oct", val: "10", days: 31 },
  { name: "Nov", val: "11", days: 30 },
  { name: "Dec", val: "12", days: 31 },
];

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.loadImage = loadImage.bind(this);
  }

  state = {
    isAuthenticated: false,
    user: null,
    token: "",
    file: null,
    src: null,
    crop: {
      unit: "px",
      width: 200,
      aspect: 1,
    },
  };

  componentDidMount() {
    const user = this.props.user;
    const cellPhone = user.cellPhone;
    let dateOfBirth = user.dateOfBirth;
    // dateOfBirth = new Date(dateOfBirth).toLocaleDateString()
    dateOfBirth = new Date(dateOfBirth);
    let birthYear = dateOfBirth.getFullYear().toString();
    birthYear = birthYear.length < 4 ? "0" + birthYear : birthYear;
    let birthMonth = (dateOfBirth.getMonth() + 1).toString();
    birthMonth = birthMonth.length === 1 ? "0" + birthMonth : birthMonth;
    let birthDay = dateOfBirth.getDate().toString();
    dateOfBirth = `${birthYear}-${birthMonth}-${birthDay}`;
    const firstName = user.firstName;
    const lastName = user.lastName;
    const gender = user.gender;
    const genderSpec = user.genderSpec;
    const handle = user.cashtag;
    const twitterHandle = user.twitterHandle;
    const twitterId = user.twitterId;
    const zipcode = user.zipcode;
    const profilePic = this.props.user.profilePic;
    debugger;
    const profilePicPath = profilePic.match(/^http/)
      ? profilePic
      : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
    const currentNumSuffix =
      /profilePic(\d)/.exec(profilePic) && /profilePic(\d)/.exec(profilePic)[1];
    //because of Chrome's mobile UI issues, mobile will use separate day & month & year inputs --> hence the inclusion of birthDay, birthMonth, birthYear
    this.setState({
      profilePic: profilePicPath,
      cellPhone,
      dateOfBirth,
      firstName,
      lastName,
      handle,
      twitterHandle,
      twitterId,
      zipcode,
      gender: genderSpec ? genderSpec : gender,
      currentNumSuffix,
      birthDay,
      birthMonth,
      birthYear,
    });
  }

  componentWillUnmount() {
    this.props.refreshUser();
  }

  twitterSuccess = (response) => {
    let userId;
    response
      .json()
      .then((res) => {
        let token = res["oauth_token"];
        let userHandle = res["screen_name"];
        userId = res["user_id"];
        this.setState({ token, userHandle, userId });
        if (token) {
          // disable link during next flureefetch >> transact userId & userHandle
          const transaction = [
            {
              _id: this.props.user._id,
              twitterHandle: userHandle,
              twitterId: userId,
            },
          ];
          debugger;
          return flureeFetch("/transact", transaction);
        } else {
          debugger;
        }
      })
      .then((res) => {
        if (res.status === 200) {
          if (!localStorage.getItem("fabricUser")) {
            localStorage.setItem("fabricUser", this.props.user._id);
          }
          this.setState({ twitterId: userId || true });
          return;
        } else {
          throw new Error("Error Authenticating with Twitter");
        }
      })
      .catch((err) => {
        if (!localStorage.getItem("fabricUser")) {
          localStorage.setItem("fabricUser", this.props.user._id);
        }
        console.log(err);
        this.setState({
          error: "That Twitter account has already been associated with another Fabric user.",
        });
      });
  };

  handleFormChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      function loadImagePromise() {
        return new Promise((resolve, reject) => {
          loadImage(
            file,
            (img) => {
              resolve(img);
            },
            { orientation: true, canvas: true, meta: true }
          );
        });
      }

      loadImagePromise().then((canvas) => {
        this.setState({ src: canvas.toDataURL(), fileType: "image/jpeg" });
      });
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        this.setState({ data_uri: canvas.toDataURL("image/jpeg") });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  uploadPhoto = async (fileType, data_uri, handle, currentNumSuffix) => {
    let fileSuffix;
    if (fileType === "image/jpeg" || fileType === ".jpeg") {
      fileSuffix = ".jpeg";
    } else if (fileType === "image/png" || fileType == ".png") {
      fileSuffix = ".png";
    } else {
      this.setState({
        error: "Please upload either a photo in either a .JPG or .PNG format",
      });
      return;
    }
    if (fileSuffix !== undefined && handle !== undefined) {
      const fileName = `user/${handle}/profilePic${
        currentNumSuffix ? parseInt(currentNumSuffix) + 1 : "1"
      }${fileSuffix}`;
      this.props.beginLoading("Uploading Photo...");
      /* --- COMMENTED OUT FOR DEVELOPMENT ONLY --- */
      debugger;
      return trackPromise(
        fetch(`${serverURL}s3/upload-file`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            buffer: data_uri,
            name: fileName,
            type: fileType,
          }),
        })
          .then((res) => {
            this.props.endLoading("Uploading Photo...");
            return fileName;
          })
          .catch((err) => {
            this.props.endLoading("Uploading Photo...");
            this.setState({ err: JSON.stringify(err) });
            return;
          })
      );
    } else if (fileSuffix === undefined) {
      this.setState({
        error: "Please upload either a photo in either a .JPG or .PNG format",
      });
      return;
    } else {
      this.setState({ error: "Unknown error." });
      return;
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      cellPhone,
      firstName,
      lastName,
      gender,
      zipcode,
      data_uri,
      fileType,
      currentNumSuffix,
      birthYear,
      birthMonth,
      birthDay,
    } = this.state;
    let handle = /^\$/.test(this.state.handle) ? this.state.handle : `$${this.state.handle}`;
    let dateOfBirth =
      e.target.id === "mobile-form"
        ? dateToInstant(`${birthYear}-${birthMonth}-${birthDay}`)
        : dateToInstant(this.state.dateOfBirth);
    if (dateOfBirth !== dateOfBirth) {
      this.setState({
        error: "Please provide an actual calendar date for Date of Birth.",
      });
      return;
    }
    if (dateOfBirth < 0) {
      dateOfBirth = Date.now();
    }
    let transaction = {
      cellPhone,
      dateOfBirth: new Date(dateOfBirth).toISOString(),
      firstName,
      lastName,
      gender,
      zipcode,
    };
    let errors = Object.values(transaction).filter((v) => !v);
    if (errors.length > 0) {
      this.setState({ error: "Please complete all fields before saving." });
      return;
    }

    if (data_uri) {
      transaction["profilePic"] = await this.uploadPhoto(
        fileType,
        data_uri,
        handle,
        currentNumSuffix
      );
    }

    transaction["id"] = this.props.user.id;
    const finalTransaction = {
      "@context": {
        ...DEFAULT_CONTEXT,
        ...PROPERTY_MAP,
      },
      where: {
        id: transaction.id,
        cellPhone: "?cellPhone",
        dateOfBirth: "?dateOfBirth",
        firstName: "?firstName",
        lastName: "?lastName",
        gender: "?gender",
        zipcode: "?zipcode",
        profilePic: "?profilePic",
      },
      delete: {
        id: transaction.id,
        cellPhone: "?cellPhone",
        dateOfBirth: "?dateOfBirth",
        firstName: "?firstName",
        lastName: "?lastName",
        gender: "?gender",
        zipcode: "?zipcode",
        profilePic: "?profilePic",
      },
      insert: transaction,
    };
    debugger;
    flureeFetch("/transact", finalTransaction)
      .then((res) => {
        debugger;
        let profilePic, profilePicPath;
        // if (transaction[0]["profilePic"]) {
        profilePic = transaction.profilePic;
        profilePicPath = profilePic.match(/^http/)
          ? profilePic
          : `https://s3.amazonaws.com/${s3Bucket}/${profilePic}`;
        // }
        this.setState((prevState) => ({
          saveAccepted: true,
          profilePic: profilePicPath ? profilePicPath : prevState.profilePic,
        }));
      })
      .then((res) => this.props.refreshUser())
      .catch((res) => {
        debugger;
      });
  };

  render() {
    const { data_uri, src, crop, croppedImageUrl } = this.state;
    const { classes } = this.props;

    const submitButton = this.state.saveAccepted ? (
      <div className={clsx(classes.button, classes.saveChanges, classes.changeSaved)}>
        <Typography className={classes.buttonLabel} variant="button">
          Changes Saved!
        </Typography>
      </div>
    ) : (
      <button type="submit" className={clsx(classes.button, classes.saveChanges)}>
        Save Changes
      </button>
    );

    const { innerWidth, innerHeight, toggleNav, vh } = this.props;
    const lineHeight = (size) => String(1.3 * size) + "px";
    let fs = Font.big;
    fs = innerWidth < 768 ? Font.med : fs;
    fs = innerWidth < 350 ? Font.small : fs;

    const twitterContent = this.state.twitterId ? (
      <Typography
        style={{
          fontStyle: "italic",
          fontFamily: "PT Sans",
          fontSize: fs.subtext,
          lineHeight: lineHeight(fs.subtext),
          letterSpacing: 0,
          color: "#25AE88",
        }}
      >
        You're Connected!
      </Typography>
    ) : (
      <TwitterLogin
        style={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderWidth: 0,
          padding: "1vw",
        }}
        loginUrl={`${serverURL}twitter/access_token`}
        onFailure={(err) => console.log(err)}
        onSuccess={this.twitterSuccess}
        requestTokenUrl={`${serverURL}twitter/request_token`}
        showIcon={false}
        customHeaders={{ method: "POST" }}
      >
        <Typography
          style={{
            textDecoration: "underline",
            // fontFamily: "PT Sans",
            // fontSize: fs.subtext,
            // lineHeight: lineHeight(fs.subtext),
            letterSpacing: 0,
            color: "#282560",
          }}
          variant="body1"
        >
          Connect Twitter
        </Typography>
      </TwitterLogin>
    );

    return (
      <>
        {src && (
          <div
            style={{
              zIndex: 11,
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(50, 115, 220, 0.2)",
            }}
          >
            <div
              style={{
                maxWidth: "320px",
                margin: "200px auto",
                padding: "10px",
                backgroundColor: "#eee",
                borderRadius: "5px",
              }}
            >
              <ReactCrop
                style={{ margin: "0 auto" }}
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <Row style={{ marginTop: "5px" }}>
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={() => this.setState({ src: null })}
                    className="d-block"
                    style={{ margin: "0 auto" }}
                  >
                    Finished Cropping!
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        )}
        {/* main dashboard content */}
        <Form className={classes.formRoot} onSubmit={this.handleSubmit}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Grid container spacing={3} className={classes.grid}>
              <Grid item lg={3} xl={2} xs={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    marginRight: 40,
                    marginLeft: 40,
                  }}
                >
                  <Typography
                    style={{
                      lineHeight: "40px",
                      letterSpacing: 0,
                      color: "#282560",
                    }}
                    variant="body1"
                  >
                    Profile Photo
                  </Typography>
                  <div
                    style={{
                      width: 200,
                      height: 200,
                      borderRadius: 150,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={(!src && data_uri) || this.state.profilePic}
                    />
                  </div>
                  <Form.Group
                    controlId="profilePic"
                    style={{
                      width: 150,
                      marginBottom: 0,
                    }}
                  >
                    <Form.Label
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        cursor: "pointer",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          padding: "6px 12px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: 400,
                            color: "rgba(85, 85, 85, 0.75)",
                            textDecoration: "underline",
                          }}
                        >
                          {!src && data_uri ? (
                            <Typography variant="body1">Uploaded Successfully!</Typography>
                          ) : (
                            <Typography variant="body1">Choose File</Typography>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 25,
                              height: 25,
                              background: `transparent url(${
                                !src && data_uri ? CheckMark : Upload
                              }) 0% 0% no-repeat padding-box`,
                              opacity: 1,
                            }}
                          ></div>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => this.handleFile(e)}
                      style={{ display: "none" }}
                    ></Form.Control>
                  </Form.Group>
                </div>
              </Grid>
              <Grid item lg={9} xl={10} xs={12} style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className={classes.formContent}>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "stretch"
                        }}
                      > */}
                      <Form.Group controlId="firstName" style={{ flex: 1 }}>
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">First Name</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.firstName}
                          style={{
                            borderColor: !!this.state.firstName ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>

                      {/* </div> */}
                      <Form.Group controlId="handle">
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">$Cashtag</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={() =>
                            this.setState({
                              error:
                                "It is not possible to change your user handle during our Beta release.",
                            })
                          }
                          value={this.state.handle}
                          style={{
                            borderColor: !!this.state.handle ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="cellPhone">
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">Phone Number</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.cellPhone}
                          style={{
                            borderColor: !!this.state.cellPhone ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                    <div className={classes.formContent}>
                      {/* <Form.Group controlId="dateOfBirth">
                        <Form.Label className={classes.formLabel}><Typography variant='body1'>
                          Birthdate
                        </Typography></Form.Label>
                        <Form.Control
                          type="date"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.dateOfBirth}
                          style={{
                            borderColor: !!this.state.dateOfBirth ? "inherit" : "#EF4137"
                          }}
                        ></Form.Control>
                      </Form.Group> */}
                      <Form.Group controlId="lastName" style={{ flex: 1 }}>
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">Last Name</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.lastName}
                          style={{
                            borderColor: !!this.state.lastName ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="gender">
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">Gender</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.gender}
                          style={{
                            borderColor: !!this.state.gender ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="zipcode">
                        <Form.Label className={classes.formLabel}>
                          <Typography variant="body1">Zip Code</Typography>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={classes.formControl}
                          onChange={this.handleFormChange}
                          value={this.state.zipcode}
                          style={{
                            borderColor: !!this.state.zipcode ? "#282560" : "#EF4137",
                          }}
                        ></Form.Control>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </Grid>
              {/* <Grid
                item
                lg={3}
                xl={2}
                xs={12}
                style={{ alignItems: "flex-start", display: "flex" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={classes.twitterBox}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",

                        background: "#29AAE1 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 6px #00000029",
                        borderRadius: 35,
                        width: 50,
                        height: 50,
                      }}
                    >
                      <img style={{ width: 30, height: 30 }} src={twitterImg} />
                    </div>
                    {twitterContent}
                  </div>
                </div>
              </Grid> */}
            </Grid>
          </div>
          {this.state.error && (
            <Typography
              style={{
                textAlign: "center",
                color: "red",
                fontFamily: "Open Sans",
                fontSize: 20,
                lineHeight: "28px",
                marginBottom: 61,
              }}
            >
              {this.state.error}
            </Typography>
          )}
          <div className={classes.buttonContainer}>
            {/* <NavLink to="/reset">
              <Typography
                style={{
                  textDecoration: "underline",
                  fontFamily: "Open Sans",
                  fontSize: 30,
                  lineHeight: "41px",
                  letterSpacing: 0,
                  color: "#EF4137",
                  opacity: 1
                }}
              >
                Reset Password
              </Typography>
            </NavLink> */}
            {submitButton}
          </div>
        </Form>

        {/* small nav buttons */}
        {/* <div style={{ position: 'absolute', top: 20, right: 20, zIndex: 100 }}>
                    <NavIcons user={this.props.user} imgSrc={this.state.profilePic} />
                </div> */}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(UserInfo);
