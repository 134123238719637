import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent, Box, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import get from "lodash.get";
import UserContext from "../contexts/UserContext";
import ApplyModal from "./ApplyModal";
import { PlaidLink } from "react-plaid-link";
import { plaidPublicKey, serverURL } from "../appConfig";
import { Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
// import { getToken } from "../flureeFetch";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  content: {
    textAlign: "center",
    width: "80%",
    marginTop: 5,
    marginBottom: 30,
  },
  inner: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    marginTop: 20,
    width: "80%",
  },
  message: {
    textAlign: "center",
    fontFamily: "Futura",
    fontWeight: "medium",
    fontSize: 16,
    lineHeight: "21px",
  },
  disabledButton: {
    opacity: 0.5,
  },
  cashout: {
    borderRadius: 20,
    width: 150,
  },
  cashbox: {
    background: theme.palette.primary.main,
    height: 70,
    width: "50%",
    borderRadius: 10,
  },
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  subheader: {
    fontSize: theme.typography.pxToRem(12),
  },
  button: {
    borderRadius: 30,
    minWidth: 250,
    backgroundColor: theme.palette.primary.dark,
    // boxShadow: '1px 1px 6px #000029',
    padding: "auto 10px",
    font: "normal normal bold 16px/21px Futura",
    color: "#FFFFFF",
    height: 55,
    borderColor: theme.palette.primary.dark,
    borderWidth: 3,
    borderStyle: "solid",
    "&:hover:not(:disabled)": {
      color: theme.palette.primary.dark,
      backgroundColor: "transparent",
    },
    "&:disabled": {
      opacity: 0.5,
    },
  },
  buttonReg: {
    borderRadius: 20,
    width: 150,
    background: "#EF4137",
  },
  topLabel: {
    marginBottom: 20,
  },
  bottomLabel: {
    fontSize: 12,
    color: "#EF4137",
    marginTop: 20,
  },
  cashoutButtonLabel: {
    ...theme.typography.button,
    textTransform: "none",
    color: "#FFFFFF",
    lineHeight: "normal",
  },
  legalText: {
    font: "normal normal normal 10px/19px Open Sans",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#9D9DA3",
    width: "80%",
    marginLeft: "2.5%",
    marginTop: 30,
    // marginTop: 50,
    [theme.breakpoints.down("xs")]: {
      width: "98%",
      // marginTop: 15,
      fontSize: 10,
    },
  },
}));

function FinancialIntegration() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const threshold = 5000;
  const walletBalance = get(user, ["user/wallet", "wallet/balance"]);
  const walletEarnings = get(user, ["user/wallet", "wallet/earnings"]);
  const applied = get(user, ["user/applied"]);
  const [socialCashAmt, setSocialCashAmt] = useState(walletBalance);
  const plaidId = null; //user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"]; //get(user, ["user/plaid"]);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(user);
  const onSuccess = async (token, metadata) => {
    // send token to server
    // console.log(token);
    // console.log(metadata);
    const { public_token, account_id } = metadata;
    const [user_id, first_name, last_name, email] = [
      user._id,
      user["user/firstName"],
      user["user/lastName"],
      user["user/email"],
    ];
    const dwolla_customer_location =
      user["user/plaid"] && user["user/plaid"]["plaid/dwollaCustomerLocation"];
    const dwolla_funding_source_location =
      user["user/plaid"] && user["user/plaid"]["plaid/dwollaFundingSourceLocation"];
    const plaid_subject_id = user["user/plaid"] && user["user/plaid"]._id;
    // const flureeToken = await getToken();
    const body = {
      public_token,
      user_id,
      account_id,
      first_name,
      last_name,
      email,
      dwolla_customer_location,
      dwolla_funding_source_location,
      plaid_subject_id,
      // flureeToken,
    };
    trackPromise(
      fetch(`${serverURL}get_access_token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      })
        .then((res) => {
          // console.log({ res });
          window.location.reload();
          return;
        })
        .catch((err) => {
          console.log({ err });
          return;
        })
    );
    return;
  };

  const handleDisconnect = () => {
    // call Plaid Rmove Item API
  };

  return (
    <Box className={classes.root}>
      {/* <CardHeader
        title="Financial Integration"
        subheader={user['user/plaid'] && user['user/plaid']["plaid/dwollaFundingSourceLocation"] ? "Your bank has been successfully connected to Fabric!" :
          "Link your bank to Fabric to cash out your CA$H!"}
        // classes={{ subheader: classes.subheader }}
        titleTypographyProps={{ variant: 'h1' }}
        subheaderTypographyProps={{ variant: 'body1' }}
      /> */}
      {applied ? (
        <Typography variant="h1" className={classes.title}>
          Access your Fabric Debit Card Account
        </Typography>
      ) : threshold <= user.wallet.totalEarnings ? (
        <Typography variant="h1" className={classes.title}>
          Unlock your Fabric Debit Card.
        </Typography>
      ) : (
        <Typography variant="h1" className={classes.title}>
          Earn 5000 CA$H to unlock your Fabric Debit Card.
        </Typography>
      )}
      {/* <CardContent className={classes.content}>
        <div className={classes.inner}>
          {plaidId && (
            <>
              <Typography
                variant="subtitle2"
                color="secondary"
                className={classes.topLabel}
              >
                Your bank is connected to Fabric!
              </Typography>
              <Button
                variant="contained"
                className={classes.buttonReg}
                classes={{ label: classes.buttonLabel }}
                onClick={handleDisconnect}
              >
                Disconnect Bank
              </Button>
              <Typography variant="subtitle2" className={classes.bottomLabel}>
                * Note: Disconnecting your linked bank will diable your ability
                to cash out until you relink your bank.
              </Typography>
            </>
          )}
          {user['user/plaid'] &&
          user['user/plaid']['plaid/dwollaFundingSourceLocation'] ? (
            <Button
              component={Link}
              variant="contained"
              className={classes.button}
              classes={{ label: classes.cashoutButtonLabel }}
              color="secondary"
              to="/redeem"
            >
              Cash Out
            </Button>
          ) : (
            <PlaidLink
              clientName="Your app name"
              env="sandbox"
              product={['auth', 'transactions']}
              publicKey={plaidPublicKey}
              onSuccess={onSuccess}
              className={classes.button}
            >
              Link Your Bank
            </PlaidLink>
          )}
        </div>
      </CardContent> */}
      {applied ? (
        <>
          <Typography className={classes.content}>
            You earned 5000 CA$H and unlocked your Fabric Debit Card!
          </Typography>
        </>
      ) : (
        <>
          <div style={{ marginBottom: 40 }}></div>
          <button
            className={classes.button}
            disabled={walletEarnings < threshold}
            onClick={handleOpen}
          >
            Unlock your Fabric Card
          </button>
        </>
      )}
      <ApplyModal open={open} onClose={handleClose} />
    </Box>
  );
}

FinancialIntegration.propTypes = {};

export default FinancialIntegration;
