import React, { Component } from "react";
import { Row, Col, Button, Form, Modal } from "react-bootstrap";
import { flureeFetch, getToken } from "../flureeFetch";
import { serverURL } from "../appConfig";
import get from "lodash.get";
import moment from "moment";
import ReactCrop from "react-image-crop";
import * as loadImage from "blueimp-load-image";
import { Link, withRouter } from "react-router-dom";
import { Container, Hidden, IconButton, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "react-image-crop/dist/ReactCrop.css";
import { withStyles } from "@material-ui/core/styles";
import { trackPromise } from "react-promise-tracker";
import { postcodeValidator } from "postcode-validator";
import heic2any from "heic2any";
// import fetch from "isomorphic-fetch";
// import convert from "heic-convert";
// import { promisify } from "util";

import Background from "../assets/img/fabric_logo_hd.png";
import StepOneBackground from "../assets/img/StepOne/Asset3.png";
import StepOneBackground2 from "../assets/img/StepOne/Asset1.png";
import StepOneBackground3 from "../assets/img/StepOne/Asset2.png";
import Lock from "../assets/img/StepOne/lock@2x.png";
import Value from "../assets/img/StepOne/value@2x.png";
import Upload from "../assets/img/StepOne/upload-button@2x.png";
import CheckMark from "../assets/img/StepOne/checkMark.png";
import Heads from "../assets/img/StepOne/users@2x.png";
import Logo from "../assets/img/fabric_logo_hd.png";
import Card from "../assets/img/thumbnail_virtual_card_front.png";
import Phone from "../assets/img/Fabric-phone-mockup.png";

import marked from "marked";
import tos from "../assets/tos.md";
import privacyPolicyRaw from "../assets/privacyPolicyRaw.md";

import clsx from "clsx";
import validZips from "../utils/justZips.json";

import constants from "../utils/constants";
const { DEFAULT_CONTEXT, CLASS_TYPES, PROPERTY_MAP } = constants;

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // height: '100%',
    // minwidth: 1366,
    width: "100%",
    backgroundColor: "#F4FBFF",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
  inner: {
    // minWidth: 623,
    // maxWidth: 623,
    // height: 754,
    width: "50%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    // alignItems: "stretch",
    // marginRight: 30,
    backgroundColor: "#F4FBFF",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
      marginRight: 0,
    },
  },
  innerNoSplash: {
    width: "71%",
    // height: '75%',
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    aignItems: "center",
    alignContents: "stretch",
    backgroundColor: "#FFFFFF",
    // postion: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   marginTop: 20
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      // marginTop: 200
      // height: '60%'
    },
  },
  authenticationInner: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      justifyContent: "flex-start",
    },
  },
  innerFullWidth: {
    alignSelf: "flex-start",
    margin: "135px auto",
  },
  topperBar: {
    // display: 'block',
    // position: 'relative',
    // top: 0,
    backgroundColor: "#3C97D2",
    width: "100%",
    textAlign: "center",
    // position: 'absolute',
    border: "1px solid #3C97D2",
    alignSelf: "flex-start",
    // height: 60
    // top: 0,
    [theme.breakpoints.down("xs")]: {
      position: "fixed",
      top: 0,
    },
  },
  topperBarText: {
    color: "#FFFFFF",
    font: "normal normal bold 20px/26px PT Sans",
    letterSpacing: 0,
    margin: "14px auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    // postion: 'relative',
    maxWidth: 800,
    width: "100%",
    // marginTop: 100,
  },
  headerWrapper: {
    maxWidth: 600,
    width: "95%",
    // border: '1px solid red',
    [theme.breakpoints.down("xs")]: {
      maxWidth: 300,
    },
  },
  header: {
    color: "#4096D2",
    font: "normal normal normal 40px/52px PT Sans", // height: 65,
    letterSpacing: 0,
    textAlign: "center",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      fontSize: 32,
      lineHeight: "42px",
      marginBottom: 15,
      // marginTop: 100,
      marginLeft: 0,
    },
  },
  topperHeader: {
    marginTop: 45,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "flex-start",
    width: "75%",
    marginTop: 30,
    // margin: '40px 45px',
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: 10,
    },
  },
  outter: {
    // margin: '40px 45px',
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      // display: 'none'
      alignItems: "center",
      backgroundColor: "#FFFFFF",
    },
  },
  label: {
    font: "normal normal normal 20px/27px Open Sans",
    color: theme.palette.text.primary, //"#282560",
    lineHeight: "27px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "21px",
    },
  },
  control: {
    borderWidth: 3,
    borderColor: theme.palette.text.primary,
    height: 54,
    fontFamily: "PT Sans",
    fontSize: 20,
    // fontStyle: 'italic',
    backgroundColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      borderWidth: 2,
      height: 40,
    },
  },
  button: {
    // display: 'block',
    maxWidth: 256,
    width: "100%",
    height: 54,
    font: "normal normal normal 20px/26px PT Sans",
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      // width: '43vw',
      height: 45,
      fontSize: 18,
      margin: 5,
    },
  },
  subheader: {
    color: "#282560",
    fontFamily: "Open Sans",
    fontSize: 20,
    // height: 36,
    lineHeight: "27px",
    // width: '75%',
    textAlign: "center",
    display: "block",
    marginTop: 30,
    // marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginBottom: 20,
    },
  },
  stepOneItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 33,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
      width: "90%",
    },
  },
  stepOneItem: {
    font: "normal normal normal 20px/27px Open Sans",
    letterSpacing: 0,
    color: "#282560",
    width: "60%",
    textAlign: "center",
    marginBottom: 20,
    // display: 'block',
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "20px",
    },
  },
  stepOneBtnContainer: {
    marginTop: 150,
    // marginBottom: 100,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  formSubmit: {
    marginTop: 65,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
  stepOneHeader: {
    marginBottom: 136,
    font: "normal normal normal 60px/78px PT Sans",
    color: "#4096D2",
    // border: '1px solid lime',
    [theme.breakpoints.down("sm")]: {
      // marginBottom: 0
      textAlign: "center",
      fontSize: 36,
      lineHeight: "38px",
      marginBottom: 30,
    },
  },
  legal: {
    font: "normal normal normal 14px/19px Open Sans",
    textAlign: "center",
    letterSpacing: "0px",
    color: "#9D9DA3",
    width: "65%",
    marginTop: 50,
    [theme.breakpoints.down("xs")]: {
      width: "98%",
      marginTop: 15,
      fontSize: 10,
    },
  },
  regErrorMessage: {
    color: "red",
    fontSize: "14px",
    fontFamily: "Open Sans",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  stepTwoHeader: {
    marginBottom: 35,
  },
  stepTwoInfoWrapper: {
    // justifyContent: 'flex-start',
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  stepTwoForm: {
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
    },
  },
  stepTwoFormField: {
    marginBottom: 40,
  },
  stepTwoContinue: {
    // position: 'relative',
    // top: 30,
    marginTop: 100,
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
    },
  },
  profilePhoto: {
    width: "100%",
    height: 54,
    borderWidth: 3,
    borderColor: "#282560",
    borderStyle: "solid",
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "6px 12px",
    fontWeight: 100,
    [theme.breakpoints.down("sm")]: {
      borderWidth: 2,
      height: 40,
    },
  },
  profilePhotoLabel: {
    font: "normal normal normal 20px/30px Open Sans",
    // marginTop: 3,
    // fontStyle: 'italic',
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "17px",
    },
  },
  stepThreeInfoWrapper: {
    justifyContent: "flex-start",
    marginTop: 175,
    [theme.breakpoints.down("md")]: {
      marginTop: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 60,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 50,
      width: "98%",
    },
  },
  stepThreeSubheader: {
    width: "55%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  stepThreeButton: {
    marginTop: 60,
    [theme.breakpoints.down("xs")]: {},
  },
  checkboxGroup: {
    display: "flex",
    alignItems: "center",
  },
  checkboxInput: {
    margin: "0px 7px 0px 0px !important",
  },
  checkboxLabel: {
    marginBottom: 0,
  },
  checkboxLabelButton: {
    fontWeight: "inherit",
    color: "rgb(227, 132, 123)",
    padding: 0,
    "&:hover": {
      color: "#EF4137",
      textDecoration: "none",
    },
    "&:visited": {
      borderColor: "transparent",
    },
    "&:active": {
      borderColor: "transparent",
    },
  },
  modal: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "40vh",
    },
  },
  modalDialog: {
    height: "inherit !important",
  },
  modalBody: {
    // margin: 20,
    maxHeight: "50vh",
    overflowY: "auto",
    "& a": {
      color: "#4096D2",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "80vh",
    },
  },
  authSplashImage: {
    width: "50%",
    minHeight: "100vh",
    height: "100%",
    background: `#FFFFFF url(${Background}) center / contain no-repeat padding-box`,
    opacity: 1,
    // backgroundColor: '#FFFFFF',
    backgroundSize: "60%",
    // alignSelf: 'stretch'
  },
  authSplashCard: {
    background: `#FFFFFF url(${Card}) no-repeat padding-box`,
    backgroundPosition: "center",
    backgroundSize: "66% auto",
  },
  authSplashPhone: {
    background: `#FFFFFF url(${Card}) no-repeat padding-box`,
    backgroundPosition: "center",
    backgroundSize: "75% auto",
  },
  cornerLogo: {
    background: `transparent url(${Logo}) 0% 0% no-repeat padding-box`,
    backgroundSize: "contain",
    maxWidth: 181,
    width: "100%",
    maxHeight: 181,
    height: "100%",
    position: "absolute",
    top: 0,
    left: "1%",
    [theme.breakpoints.down("md")]: {
      maxWidth: 125,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  continueButton: {
    marginTop: 30,
  },
  boldSpan: {
    fontWeight: 700,
  },
  progBar: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobileLogo: {
    maxWidth: 75,
    height: 75,
    width: "100%",
    background: `transparent url(${Logo}) center / cover no-repeat padding-box`,
    position: "absolute",
    top: 0,
    left: 0,
  },
  backButton: {
    position: "absolute",
    top: 5,
    left: "1%",
  },
  strong: {
    color: theme.palette.grey[600],
  },
});

const checkStrength = (password) =>
  /((?=.*\d)(?=.*[A-Za-z])(?=.*[~!@#$%^&*\_\-\+=]).{12,40})/.test(password);

const monthArray = [
  { name: "Jan", val: "01", days: 31 },
  { name: "Feb", val: "02", days: 28 },
  { name: "Mar", val: "03", days: 31 },
  { name: "Apr", val: "04", days: 30 },
  { name: "May", val: "05", days: 31 },
  { name: "Jun", val: "06", days: 30 },
  { name: "Jul", val: "07", days: 31 },
  { name: "Aug", val: "08", days: 31 },
  { name: "Sep", val: "09", days: 30 },
  { name: "Oct", val: "10", days: 31 },
  { name: "Nov", val: "11", days: 30 },
  { name: "Dec", val: "12", days: 31 },
];

function dateToInstant(date) {
  return moment(date, "YYYY-MM-DD").valueOf();
}

// From https://miguelmota.com/bytes/base64-mime-regex/
function base64Suffix(encoded) {
  var result = null;

  if (typeof encoded !== "string") {
    return result;
  }

  var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

  if (mime && mime.length) {
    result = mime[1];
  }

  if (result === "image/jpeg") {
    return ".jpeg";
  } else if (result === "image/png") {
    return ".png";
  } else {
    return result;
  }
}

export const Steps = (props) => {
  const { step, stepMax } = props;
  const steps = [];
  for (let i = 1; i <= stepMax; i++) {
    steps.push(i);
  }
  return (
    <div className="arrow-steps clearfix">
      {steps.map((stepNum) => (
        <div className={step === stepNum ? "step current" : "step"}>
          {" "}
          <span>Step #{stepNum}</span>{" "}
        </div>
      ))}
    </div>
  );
};

class Authentication extends React.Component {
  state = {
    email: "",
    password: "",
    passwordConf: "",
    acceptedTos: false,
    acceptedPrivacy: false,
    modalContent: "tos",
    modalShow: false,
    tos: "",
    pp: "",
  };

  componentDidMount() {
    this.setState({ error: null });
    fetch(tos)
      .then((res) => res.text())
      .then((res) => this.setState({ tos: res }));
    fetch(privacyPolicyRaw)
      .then((res) => res.text())
      .then((res) => this.setState({ pp: res }));
  }

  responseFacebook = (response) => {
    if (response.status === "unknown") {
      this.setState({
        error: {
          message: "Something went wrong while trying to create your account through Facebook.",
        },
      });
      return;
    }
    const { name, email, userID } = response;
    const firstName = name.substr(0, name.indexOf(" "));
    const lastName = name.substr(name.indexOf(" ") + 1);
    let imageUrl = "";

    let query = {
      select: ["*"],
      from: ["user/email", email],
    };
    flureeFetch("/query", query)
      .then((res) => {
        // check if email exists
        if (res) {
          this.setState({
            error: {
              message: `There is already an account associated with this email: ${email}. Did you mean to sign in?`,
            },
          });
        } else {
          this.props.setUser({
            ...this.state.user,
            _id: "user$1",
            email,
            firstName,
            lastName,
            facebookId: userID,
          });
          this.props.changeStep(1);
        }
      })
      .catch((err) =>
        this.setState({
          error: {
            message:
              "Something went wrong trying to create your account: " + JSON.stringify(err, null),
          },
        })
      );
  };

  responseGoogle = (response) => {
    if (response.error) {
      this.setState({ error: { message: response.error } });
    } else {
      const { email, familyName, givenName, googleId, imageUrl } = response.profileObj;

      let query = {
        select: ["*"],
        from: ["user/email", email],
      };
      flureeFetch("/query", query)
        .then((res) => {
          // check if email exists
          if (res) {
            this.setState({
              error: {
                message: `There is already an account associated with this email: ${email}. Did you mean to sign in?`,
              },
            });
          } else {
            this.props.setUser({
              ...this.state.user,
              _id: "user$1",
              email,
              googleId,
              imageUrl,
              firstName: givenName,
              lastName: familyName,
            });
            this.props.changeStep(1);
          }
        })
        .catch((err) =>
          this.setState({
            error: {
              message:
                "Something went wrong trying to create your account: " + JSON.stringify(err, null),
            },
          })
        );
    }
  };

  register() {
    const { email, password, passwordConf } = this.state;
    const emailPattern =
      /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

    if (!email || !password) {
      this.setState({
        error: { message: "You must specify an email and password." },
      });
    } else if (!passwordConf || password !== passwordConf) {
      this.setState({
        error: {
          message: "Your password does not match your confirmation entry.",
        },
      });
    } else if (!emailPattern.test(email)) {
      this.setState({
        error: { message: "You must provide a valid email address." },
      });
    } else {
      // let query = {
      //   select: ["*"],
      //   from: ["user/email", email],
      // };
      // flureeFetch("/query", query).then((res) => {
      //   if (res.length > 0) {
      //     debugger;
      //     this.setState({
      //       error: {
      //         message: `There is already an account associated with this email: ${email}. Did you mean to sign in?`,
      //       },
      //     });
      //   } else {
      //     debugger;
      //     this.props.setUser({
      //       ...this.state.user,
      //       _id: "user$1",
      //       email: email,
      //       password: password,
      //     });
      //     this.props.changeStep(1);
      //   }
      // });
      const postBody = { email };
      trackPromise(
        fetch(`${serverURL}auth/verify`, {
          method: "POST",
          body: JSON.stringify(postBody),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            debugger;
            return res.json();
          })
          .then((data) => {
            debugger;
            if (data.available) {
              this.props.setUser({
                ...this.state.user,
                _id: "user$1",
                email: email,
                password: password,
              });
              this.props.changeStep(1);
            } else {
              this.setState({
                error: {
                  message: `There is already an account associated with this email: ${email}. Did you mean to sign in?`,
                },
              });
            }
          })
          .catch((err) => {
            debugger;
            console.log(err);
          })
      );
      // fetch({}, )
    }
  }

  render() {
    const { classes } = this.props;
    const { email, password, passwordConf, modalShow, acceptedTos, acceptedPrivacy, modalContent } =
      this.state;

    return (
      <Container maxWidth={"xl"} disableGutters className={classes.container}>
        <Hidden mdDown>
          <div className={classes.authSplashImage}>
            <Link to="/">
              <IconButton className={classes.backButton}>
                <ArrowBackIosIcon />
              </IconButton>
            </Link>
          </div>
        </Hidden>
        <Hidden lgUp>
          <Link to="/">
            <div className={classes.mobileLogo}></div>
          </Link>
        </Hidden>

        <div className={clsx(classes.inner, classes.authenticationInner)}>
          <h3 className={classes.header}>REGISTER</h3>
          {/* <span
              style={{
								display: "inline-block",
								textAlign: "center",
                color: "red",
                margin: "20px 0px",
								padding: "0px 20px",
								wordWrap: "normal",
                fontSize: "14px",
                fontFamily: "Open Sans",
              }}
            >
              Fabric is currently undergoing maintenance. We appreciate your patience as we make
              updates to improve your experience.
            </span> */}
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.register();
            }}
            className={classes.form}
          >
            <Form.Group controlId="email" style={{ width: "100%", marginBottom: 35 }}>
              <Form.Label className={classes.label}>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
                className={classes.control}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="password" style={{ width: "100%", marginBottom: 35 }}>
              <Form.Label className={classes.label}>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={password}
                onChange={(e) => this.setState({ password: e.target.value })}
                onBlur={(e) => {
                  if (!checkStrength(password)) {
                    this.setState({
                      error: {
                        message:
                          "Your password must be at least 12 characters and include a letter, number, and symbol (~!@#$%^&*_-+=)",
                      },
                    });
                  } else {
                    this.setState({ error: null });
                  }
                }}
                className={classes.control}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="passwordConf" style={{ width: "100%", marginBottom: 20 }}>
              <Form.Label className={classes.label}>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={passwordConf}
                onChange={(e) => {
                  this.setState({ passwordConf: e.target.value });
                  if (password === e.target.value && checkStrength(e.target.value)) {
                    this.setState({ error: null });
                  }
                }}
                onBlur={(e) => {
                  if (password !== passwordConf) {
                    this.setState({
                      error: {
                        message: "Your password must match your previous entry.",
                      },
                    });
                  } else if (!checkStrength(passwordConf)) {
                    this.setState({
                      error: {
                        message:
                          "Your password must be at least 12 characters and include a letter, number, and symbol (~!@#$%^&*_-+=)",
                      },
                    });
                  } else {
                    this.setState({ error: null });
                  }
                }}
                className={classes.control}
              ></Form.Control>
            </Form.Group>
            {this.state.error ? (
              <div
                className={classes.regErrorMessage}
                style={{
                  margin: "0px 0px 20px 0px",
                }}
              >
                {this.state.error.message}
              </div>
            ) : (
              <div style={{ margin: "0px 0px" }}></div>
            )}

            <Form.Group controlId="termsOfService">
              <Form.Check className={classes.checkboxGroup} type={"checkbox"}>
                <Form.Check.Input
                  className={classes.checkboxInput}
                  type="checkbox"
                  checked={acceptedTos}
                  onChange={() => this.setState({ acceptedTos: !acceptedTos })}
                />
                <Form.Check.Label className={classes.checkboxLabel}>
                  I agree to Fabric's{" "}
                  <button
                    type="button"
                    className={classes.checkboxLabelButton + " btn-link"}
                    onClick={() => this.setState({ showModal: true, modalContent: "tos" })}
                  >
                    Terms of Service
                  </button>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <Form.Group controlId="privacyPolicy">
              <Form.Check className={classes.checkboxGroup} type={"checkbox"}>
                <Form.Check.Input
                  className={classes.checkboxInput}
                  type="checkbox"
                  checked={acceptedPrivacy}
                  onChange={() => this.setState({ acceptedPrivacy: !acceptedPrivacy })}
                />
                <Form.Check.Label className={classes.checkboxLabel}>
                  I agree to Fabric's{" "}
                  <button
                    type="button"
                    className={classes.checkboxLabelButton + " btn-link"}
                    onClick={() => this.setState({ showModal: true, modalContent: "pp" })}
                  >
                    Privacy Policy
                  </button>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <div className="text-center">
              <Button
                className={clsx(classes.button, "brand-btn", "landing-btn")}
                type="submit"
                disabled={
                  !email ||
                  !password ||
                  !passwordConf ||
                  !acceptedTos ||
                  !acceptedPrivacy ||
                  password !== passwordConf ||
                  !checkStrength(password)
                }
                // disabled
              >
                Continue
              </Button>
            </div>
            {/* <div className="text-center" style={{ marginTop: '5px' }}>
              <Link
                to="/reset"
                className="btn"
                style={{ color: '#2C3E50', fontFamliy: 'Open Sans' }}
              >
                <small className="form-text brand-link">
                  Forgot Your Password?
                </small>
              </Link>
            </div> */}
          </Form>
        </div>
        {/* <!-- Modal --> */}
        <Modal
          className={classes.modal}
          dialogClassName={classes.modalDialog}
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          {/* <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content"> */}
          {/* <Modal.Header class="modal-header">
            <Modal.Title class="modal-title" id="exampleModalScrollableTitle">Modal title</Modal.Title>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header> */}
          <Modal.Body
            className={classes.modalBody}
            dangerouslySetInnerHTML={{
              __html:
                this.state.tos && this.state.pp && this.state.modalContent === "tos"
                  ? marked(this.state.tos)
                  : marked(this.state.pp),
            }}
          ></Modal.Body>
          <Modal.Footer class="modal-footer">
            <Button
              type="button"
              class="btn btn-secondary"
              onClick={() => this.setState({ showModal: false })}
            >
              Close
            </Button>
            {/* <button type="button" class="btn btn-primary">Save changes</button> */}
          </Modal.Footer>
          {/* </div>
  </div> */}
        </Modal>
      </Container>
    );
  }
}

class StepOne extends React.Component {
  state = {
    firstName: "", // TODO: return to "" values
    lastName: "",
    handle: "",
    cellPhone: "",
    showOverlay: false,
    modalShow: false,
  };

  setModalShow = () => {
    this.setState((prevState) => {
      return { modalShow: !prevState.modalShow };
    });
  };

  target = React.createRef(null);

  cashTagChangeHandler = (e) => {
    if (e.target.value) {
      const cashTag = e.target.value[0];
      if (cashTag !== "$") {
        this.setState({ handle: "$" + e.target.value });
      } else {
        this.setState({ handle: e.target.value });
      }
    }
  };

  componentDidMount() {
    const { firstName, lastName, handle, cellPhone, password } = this.props.user;
    this.setState({ firstName, lastName, handle, cellPhone, password });
    // this.timerHandle = setTimeout(() => {
    //     this.setState({ modalShow: true })
    // }, 1000)
  }

  // componentWillUnmount() {
  //     clearTimeout(this.timerHandle)
  // }

  submit() {
    const { firstName, lastName, handle, cellPhone, password } = this.state;
    // console.log("Ping!");
    if (
      !firstName ||
      !lastName ||
      !handle ||
      !cellPhone ||
      ((this.state.googleId || this.state.facebookId) && !password)
    ) {
      this.setState({ error: "You must complete all fields." });
    } else {
      const postBody = { handle: handle.trim() };
      trackPromise(
        fetch(`${serverURL}auth/verify`, {
          method: "POST",
          body: JSON.stringify(postBody),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            debugger;
            return res.json();
          })
          .then((data) => {
            debugger;
            if (data.available) {
              this.props.setUser({
                ...this.props.user,
                firstName,
                lastName,
                handle: handle.trim(),
                cellPhone,
                password,
              });
              this.props.changeStep(2);
            } else {
              this.setState({
                error: {
                  message: `Please choose another $Cashtag. There is already a user with the $Cashtag: ${handle}`,
                },
              });
            }
          })
      );
      // let query = {
      //   select: ["*"],
      //   from: ["user/handle", handle],
      // };

      // flureeFetch("/query", query).then((res) => {
      //   debugger;
      //   if (res.length > 0) {
      //     this.setState({
      //       error: {
      //         message: `Please choose another $Cashtag. There is already a user with the $Cashtag: ${handle}`,
      //       },
      //     });
      //   } else {
      //     this.props.setUser({
      //       ...this.props.user,
      //       firstName,
      //       lastName,
      //       handle,
      //       cellPhone,
      //       password,
      //     });
      //     this.props.changeStep(2);
      //   }
      // });
    }
  }

  render() {
    const {
      firstName,
      lastName,
      handle,
      cellPhone,
      password,
      passwordConf,
      showOverlay,
      modalShow,
    } = this.state;
    const { classes } = this.props;

    return modalShow ? (
      <Container maxWidth={"xl"} disableGutters className={classes.container}>
        {/* <div
          style={{
            minWidth: 623,
            maxWidth: 623,
            height: 754,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        > */}
        <div className={classes.outter}>
          <div className={classes.cornerLogo}></div>
          <div className={classes.innerNoSplash}>
            <div className={classes.infoWrapper}>
              {/* <div className={classes.headerWrapper}> */}
              <h3 className={clsx(classes.header)}>
                CREATE YOUR SMART PROFILE
                {/* <br /> */}
              </h3>
              <Typography component="strong">
                Your real name is required to receive a Fabric Debit Card
              </Typography>
              {this.state.error && (
                <p className={classes.regErrorMessage}>{this.state.error.message}</p>
              )}
              {/* </div> */}
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.submit();
                }}
                className={classes.form}
              >
                <Form.Group controlId="firstName">
                  <Form.Label className={classes.label}>First Name*</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Ex. Jane"
                    value={firstName}
                    onChange={(e) => this.setState({ firstName: e.target.value })}
                    className={classes.control}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="lastName">
                  <Form.Label className={classes.label}>Last Name*</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="Ex. Doe"
                    value={lastName}
                    onChange={(e) => this.setState({ lastName: e.target.value })}
                    className={classes.control}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="handle">
                  <Form.Label className={classes.label}>$Cashtag Handle*</Form.Label>
                  <Form.Control
                    type="string"
                    placeholder="$JohnDoe"
                    value={handle}
                    onChange={this.cashTagChangeHandler}
                    className={classes.control}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="cellPhone">
                  <Form.Label className={classes.label}>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Ex. (123) 456-7890"
                    value={cellPhone}
                    onChange={(e) => this.setState({ cellPhone: e.target.value })}
                    className={classes.control}
                  ></Form.Control>
                </Form.Group>
                <div className={clsx(classes.formSubmit, "text-center")}>
                  <Button
                    className={clsx(
                      classes.button,
                      // classes.continueButton,
                      "brand-btn",
                      "landing-btn"
                    )}
                    type="submit"
                    disabled={!firstName || !lastName || !handle || !cellPhone}
                  >
                    Continue
                  </Button>
                </div>
              </Form>
              <div
                className={classes.progBar}
                style={{
                  width: 511,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 50,
                  marginBottom: 30,
                }}
              >
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#4ADB77",
                  }}
                ></div>
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "none",
                    borderColor: "#4ADB77",
                  }}
                ></div>
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "none",
                    borderColor: "#4ADB77",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Container>
    ) : (
      <Container maxWidth disableGutters className={classes.container}>
        {/* <Hidden smDown>
          <div
            style={{
              minWidth: 593,
              height: 767,
              background: `transparent url(${StepOneBackground}) 0% 0% no-repeat padding-box`,
              opacity: 1
            }}
          ></div>
        </Hidden> */}
        <div className={classes.outter}>
          <Hidden mdDown>
            <div className={clsx(classes.authSplashImage, classes.authSplashCard)}></div>
          </Hidden>
          <div className={classes.inner}>
            <div className={classes.infoWrapper}>
              <p className={clsx(classes.stepOneHeader)}>WELCOME TO FABRIC</p>
              <p className={classes.stepOneItem}>
                Create your <span className={classes.boldSpan}>Smart Profile</span> and select your{" "}
                <span className={classes.boldSpan}>$Cashtag</span> handle to start earning Social
                Cash (CA$H)!
              </p>
              <p className={classes.stepOneItem}>
                Once you’ve earned 5000 CA$H you can spend your earnings using your Fabric Debit
                Card!
              </p>

              <div className={classes.stepOneBtnContainer}>
                <Button
                  className={clsx([classes.button, "brand-btn", "landing-btn"])}
                  type="submit"
                  onClick={() => this.setState({ modalShow: true })}
                >
                  Get Started!
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

class StepTwo extends React.Component {
  constructor(props) {
    super(props);
    this.loadImage = loadImage.bind(this);
  }

  state = {
    gender: "female",
    file: null,
    src: null,
    crop: {
      unit: "px",
      width: 200,
      aspect: 1,
    },
    modalShow: false,
  };

  setModalShow = () => {
    this.setState((prevState) => {
      return { modalShow: !prevState.modalShow };
    });
  };

  componentDidMount() {
    const { birthdate, zipcode } = this.props.user;
    const birthMonth = "01";
    const birthDay = "1";
    const birthYear = "1990";
    this.setState({ birthdate, zipcode, birthDay, birthMonth, birthYear });
    if (this.props.user.imageUrl) {
      const img = new Image();
      const canvas = document.getElementById("prof-photo-canvas");
      var ctx = canvas.getContext("2d");
      img.onload = function (e) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
      var data_uri = canvas.toDataURL("image/png");
      img.src = this.props.user.imageUrl;
      const fileType = base64Suffix(data_uri);
      this.setState({ profilePic: img.src, fileType });
    }
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  handleFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0];

      function loadImagePromise() {
        return new Promise((resolve, reject) => {
          debugger;
          loadImage(
            file,
            (img) => {
              // debugger;
              // if (file.type === "image/heic") {
              //   const convertImg = heic2any({ img });
              //   debugger;
              // }
              resolve(img);
            },
            {
              orientation: true,
              canvas: true,
              meta: true,
            }
          );
        });
      }

      loadImagePromise()
        .then((canvas) => {
          debugger;
          this.setState({ src: canvas.toDataURL(), fileType: "image/jpeg" });
        })
        .catch((err) => {
          console.log(err);
          debugger;
          this.setState({ error: err.message });
        });
    }
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
      this.setState({ croppedImageUrl });
    }
  }

  zipIsValid = (zip) => {
    return postcodeValidator(zip, "US") && validZips.zips.includes(Number(zip));
  };

  validateZip = (e) => {
    if (this.zipIsValid(e.target.value)) this.setState({ error: null });
    else this.setState({ error: "Must be a valid United States zip code" });
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        this.setState({ data_uri: canvas.toDataURL("image/jpeg") });
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  submit(e) {
    if (this.state.error) {
      return;
    }
    const {
      birthdate,
      gender,
      genderSpec,
      zipcode,
      data_uri,
      fileType,
      profilePic,
      birthYear,
      birthMonth,
      birthDay,
    } = this.state;
    const { _id } = this.props.user;
    const handle = this.props.user.handle;
    let birthInstant =
      e.target.id === "mobile-form"
        ? dateToInstant(`${birthYear}-${birthMonth}-${birthDay}`)
        : dateToInstant(birthdate);
    let fileSuffix;
    if (fileType === "image/jpeg" || fileType === ".jpeg") {
      fileSuffix = ".jpeg";
    } else if (fileType === "image/png" || fileType == ".png") {
      fileSuffix = ".png";
    } else {
      this.setState({ err: "" });
    }

    if (profilePic) {
      debugger;
      if (!birthdate || !gender || !zipcode) {
        this.setState({ error: "You must complete all fields." });
      } else {
        this.props.setUser({
          ...this.props.user,
          birthInstant: Date.now(),
          gender,
          genderSpec,
          zipcode,
          profilePic,
          fileType,
        });
        this.props.changeStep(3);
      }
    } else if (fileSuffix !== undefined && handle !== undefined) {
      debugger;
      const fileName = `user/${handle}/profilePic${fileSuffix}`;
      //this.props.beginLoading("Uploading Photo...");
      /* --- COMMENTED OUT FOR DEVELOPMENT ONLY --- */
      trackPromise(
        fetch(`${serverURL}s3/upload-file`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            buffer: data_uri,
            name: fileName,
            type: fileType,
          }),
        })
          .then(
            (res) => {
              /* ------------------------------------------- */

              //this.props.endLoading();
              // console.log(fileName);
              debugger;
              // console.log({ what: res.data });
              this.props.setUser({
                ...this.props.user,
                birthInstant,
                gender,
                genderSpec,
                zipcode,
                data_uri,
                fileType,
                fileName,
              });
              this.props.changeStep(3);
            }
            /* --- COMMENTED OUT FOR DEVELOPMENT ONLY --- */
          )
          .catch((err) => {
            debugger;
            this.setState({ err: JSON.stringify(err) });
          })
      );
    } else if (fileSuffix === undefined) {
      debugger;
      this.setState({
        error:
          "Your file did not upload correctly. Please check that it is a .jpg or .png and try again.",
        invalid: "profile-photo",
      });
    } else if (_id === undefined) {
      debugger;
      this.setState({
        error: "Unknown error. This user does not exist. Please try registering again.",
      });
    } else {
      this.setState({ error: "Unknown error." });
    }
    /* ------------------------------------------- */
  }

  render() {
    const {
      birthdate,
      gender,
      zipcode,
      data_uri,
      src,
      crop,
      croppedImageUrl,
      modalShow,
      birthMonth,
      birthYear,
      birthDay,
    } = this.state;
    const { imageUrl } = this.props.user;
    const { classes } = this.props;

    return (
      <>
        {src && (
          <div
            style={{
              zIndex: 11,
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: "rgba(50, 115, 220, 0.2)",
            }}
          >
            <div
              style={{
                maxWidth: "320px",
                margin: "200px auto",
                padding: "10px",
                backgroundColor: "#eee",
                borderRadius: "5px",
              }}
            >
              <ReactCrop
                style={{ margin: "0 auto" }}
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              <Row style={{ marginTop: "5px" }}>
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={() => this.setState({ src: null })}
                    className="d-block"
                    style={{ margin: "0 auto" }}
                  >
                    Finished Cropping!
                  </Button>
                </div>
              </Row>
            </div>
          </div>
        )}
        <Container maxWidth={"xl"} disableGutters className={classes.container}>
          {/* <Hidden smDown>
            <div
              style={{
                minWidth: 605,
                width: 605,
                height: 781,
                background: `transparent url(${StepOneBackground3}) 0% 0% no-repeat padding-box`,
                opacity: 1
              }}
            ></div>
          </Hidden> */}
          <div className={classes.outter}>
            <div className={classes.cornerLogo}></div>
            <div className={clsx(classes.innerNoSplash)}>
              <div className={classes.topperBar}>
                <Typography className={classes.topperBarText}>
                  Congrats! You’re at LEVEL 1 and you just earned 100 CA$H!
                </Typography>
              </div>
              <div className={clsx(classes.infoWrapper, classes.stepTwoInfoWrapper)}>
                <Typography
                  className={clsx(classes.header, classes.topperHeader, classes.stepTwoHeader)}
                >
                  ADDITIONAL DETAILS
                </Typography>
                {this.state.error && (
                  <div className={classes.regErrorMessage}>{this.state.error.message}</div>
                )}

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submit(e);
                  }}
                  className={clsx(classes.form, classes.stepTwoForm)}
                  style={{
                    margin: 0,
                  }}
                >
                  {/* <Form.Group
                    controlId="birthdate"
                    style={{ width: '100%', marginBottom: 15 }}
                  >
                    <Form.Label className={classes.label}>
                      Birthdate *
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Birthdate"
                      value={birthdate}
                      onChange={(e) =>
                        this.setState({ birthdate: e.target.value })
                      }
                      className={classes.control}
                    ></Form.Control>
                  </Form.Group> */}

                  <Form.Group
                    controlId="gender"
                    style={{ flex: 1 }}
                    className={classes.stepTwoFormField}
                  >
                    <Form.Label className={classes.label}>Gender *</Form.Label>
                    <Form.Control
                      as="select"
                      value={gender}
                      onChange={(e) => this.setState({ gender: e.target.value })}
                      className={clsx("custom-select", classes.control)}
                    >
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                      <option value="other">Other (Please specify)</option>
                    </Form.Control>
                  </Form.Group>
                  {/* </div> */}

                  {gender === "other" ? (
                    <Form.Group
                      controlId="genderSpec"
                      style={{ flex: 1 }}
                      className={classes.stepTwoFormField}
                    >
                      <Form.Label className={classes.label}>
                        Please specify your gender *
                      </Form.Label>
                      <Form.Control
                        type="input"
                        className={classes.control}
                        onChange={(e) => this.setState({ genderSpec: e.target.value })}
                      ></Form.Control>
                    </Form.Group>
                  ) : null}

                  <Form.Group
                    controlId="zipcode"
                    style={{ flex: 1 }}
                    className={classes.stepTwoFormField}
                  >
                    <Form.Label className={classes.label}>Zip Code *</Form.Label>
                    <Form.Control
                      type="string"
                      placeholder="12345"
                      value={zipcode}
                      onChange={(e) => this.setState({ zipcode: e.target.value })}
                      className={clsx(classes.control)}
                      onBlur={this.validateZip}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group
                    controlId="profilePic"
                    style={{
                      flex: 1,
                    }}
                    // className={classes.stepTwoFormField}
                  >
                    <Form.Label
                      style={{
                        width: "100%",
                      }}
                    >
                      <div className={classes.label}>Profile Photo *</div>
                      <div className={classes.profilePhoto}>
                        <div className={classes.profilePhotoLabel}>
                          {!src && data_uri ? "Uploaded Successfully!" : "Choose File"}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              width: 25,
                              height: 25,
                              background: `transparent url(${
                                !src && data_uri ? CheckMark : Upload
                              }) 0% 0% no-repeat padding-box`,
                              backgroundSize: "contain",
                              opacity: 1,
                            }}
                          ></div>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/jpeg,image/png"
                      onChange={(e) => this.handleFile(e)}
                      style={{ display: "none" }}
                    ></Form.Control>
                  </Form.Group>
                  {/* </div> */}
                  <div>
                    <Typography className={classes.regErrorMessage}>{this.state.error}</Typography>
                  </div>
                  <div className="text-center">
                    <Button
                      className={clsx(
                        classes.button,
                        classes.stepTwoContinue,
                        "brand-btn",
                        "landing-btn"
                      )}
                      type="submit"
                      // disabled={
                      //   // !birthdate ||
                      //   !gender ||
                      //   !zipcode ||
                      //   (!data_uri && !croppedImageUrl && !imageUrl)
                      // }
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
                <div
                  className={classes.progBar}
                  style={{
                    width: 511,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 50,
                    marginBottom: 55,
                  }}
                >
                  <div
                    style={{
                      width: 163,
                      height: 0,
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: "#4ADB77",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 163,
                      height: 0,
                      borderWidth: 2,
                      borderStyle: "solid",
                      borderColor: "#4ADB77",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 163,
                      height: 0,
                      borderWidth: 2,
                      borderStyle: "none",
                      borderColor: "#4ADB77",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

class StepThree extends React.Component {
  state = {
    topLevelCategories: [],
    selectedCategories: [],
    modalShow: false,
  };

  setModalShow = () => {
    this.setState((prevState) => {
      return { modalShow: !prevState.modalShow };
    });
  };

  // componentWillUnmount() {
  //     clearTimeout(this.timerHandle)
  // }

  // componentDidMount() {
  //   const categories = {
  //     select: ["descriptiveName", { children: ["*"] }],
  //     from: ["category/descriptiveName", "root"],
  //   };
  //   // this.timerHandle = setTimeout(() => {
  //   //     this.setState({ modalShow: true })
  //   // }, 1000)
  //   flureeFetch("/query", categories).then((res) => {
  //     const topLevelCategories = get(res, "children");

  //     if ("This should be changed to FALSE or just DELETED ENTIRELY when we add real categories") {
  //       this.setState({ selectedCategories: ["root"] });
  //       return;
  //     }

  //     this.setState({ topLevelCategories: topLevelCategories });
  //   });
  // }

  handleSelectCategory(descName) {
    const selectedCats = this.state.selectedCategories;

    if (selectedCats.includes(descName)) {
      let filtered = selectedCats.filter((val, index, arr) => val !== descName);
      this.setState({ selectedCategories: filtered });
    } else {
      selectedCats.push(descName);
      this.setState({ selectedCategories: selectedCats });
    }
  }

  submit() {
    const {
      _id,
      email,
      password,
      firstName,
      lastName,
      cellPhone,
      zipcode,
      birthInstant,
      gender,
      genderSpec,
      fileName,
      profilePic,
      googleId,
    } = this.props.user;
    let handle = /^\$/.test(this.props.user.handle)
      ? this.props.user.handle
      : `$${this.props.user.handle}`;
    handle = handle.trim();
    const { selectedCategories } = this.state;
    const shoppingInterest = [];
    selectedCategories.forEach((cat) => shoppingInterest.push(["category/descriptiveName", cat]));
    shoppingInterest.push(["category/descriptiveName", "fabric"]);
    debugger;
    let userData = {
      id: "fabric:User/" + handle,
      type: CLASS_TYPES.user,
      email: email.toLowerCase(),
      password: password,
      handle,
      firstName,
      lastName,
      cellPhone: cellPhone,
      zipcode: zipcode,
      dateOfBirth: new Date().toISOString(),
      gender: genderSpec ? genderSpec : gender,
      // shoppingInterests: shoppingInterest,
      profilePic: profilePic ? profilePic : fileName,
      wallet: {
        id: "fabric:Wallet/" + handle,
        type: CLASS_TYPES.wallet,
        name: handle + "Wallet",
        earnings: 300,
        balance: 300,
      },
      applied: false,
    };
    if (googleId) {
      userData["googleId"] = googleId;
    }

    const transaction = {
      "@context": {
        ...DEFAULT_CONTEXT,
        ...PROPERTY_MAP,
      },
      insert: [userData],
    };

    //this.props.beginLoading("Registering User...");
    debugger;
    trackPromise(
      // getToken()
      //   .then((token) => {
      // console.log(token);
      // console.log(userData);
      // return
      fetch(`${serverURL}register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          transaction,
          // token,
        }),
      })
        // })
        .then((res) => {
          debugger;
          return res.json();
        })
        .then((json) => {
          debugger;
          if (!json.token) {
            throw new Error("No token received");
          }
          // this.props.endLoading("Registering User...");
          // window.localStorage.setItem("fabricUser", json.id.toString());
          window.localStorage.setItem("stringBean", json.token);
          this.props.refreshUser().then((res) => {
            this.props.history.push("/");
          });
        })
        .catch((err) => {
          debugger;
          console.log(err);
          // this.props.endLoading("Registering User...");
          // this.props.refreshUser().then(res => this.props.history.push("/"));
        })
    );
  }

  render() {
    const selectedCats = this.state.selectedCategories;
    const { classes } = this.props;

    return (
      <Container maxWidth={"xl"} disableGutters className={classes.container}>
        {/* <Hidden smDown>
          <div
            style={{
              minWidth: 601,
              height: 754,
              background: `transparent url(${Background}) 0% 0% no-repeat padding-box`,
              opacity: 1
            }}
          ></div>
        </Hidden> */}

        <div className={clsx(classes.outter)}>
          {/* <div className={classes.cornerLogo}></div> */}
          <Hidden mdDown>
            <div className={clsx(classes.authSplashImage, classes.authSplashPhone)}></div>
          </Hidden>
          <div className={classes.inner}>
            <div className={classes.topperBar}>
              <Typography className={classes.topperBarText}>
                Congrats! You’re at LEVEL 2 and just earned ANOTHER 100 Social CA$H Tokens!
              </Typography>
            </div>
            <div className={clsx(classes.infoWrapper, classes.stepThreeInfoWrapper)}>
              <Typography className={clsx(classes.header, classes.topperHeader)}>
                PROFILE COMPLETE
              </Typography>
              <Typography className={clsx(classes.subheader, classes.stepThreeSubheader)}>
                Congratulations! You’ve Taken the First Steps Toward Data Ownership.
              </Typography>
              <Typography className={clsx(classes.subheader, classes.stepThreeSubheader)}>
                Visit your dashboard to watch ads from top brands and continue earning CA$H!
              </Typography>
              <div className={classes.stepThreeButton}>
                <Button
                  className={clsx(classes.button, "brand-btn", "landing-btn")}
                  type="submit"
                  onClick={() => this.submit()}
                >
                  Go to Dashboard
                </Button>
              </div>
              <div
                className={classes.progBar}
                style={{
                  maxWidth: 511,
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 180,
                  marginBottom: 50,
                }}
              >
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#4ADB77",
                  }}
                ></div>
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#4ADB77",
                  }}
                ></div>
                <div
                  style={{
                    width: 163,
                    height: 0,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: "#4ADB77",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

class Register extends Component {
  state = {
    step: 0,
    handle: null,
    // uncomment user key for debugging registration screens
    // user: {
    //   firstName: "tester",
    //   lastName: "testerosa",
    // },
  };

  componentDidMount() {
    this.setState({ error: null });
  }

  render() {
    const { innerHeight } = this.props;
    const { classes } = this.props;

    return (
      <div
        style={{
          display: "flex",
          height: innerHeight,
          flexDirection: "column",
        }}
      >
        {/* <div style={{ marginTop: "40px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div>
                        {
                            this.state.step !== 0
                            ?
                            <Steps step={this.state.step} stepMax={3} />
                            :
                            null
                        }
                    </div>
                </div> */}
        {this.state.step === 0 ? (
          <Authentication
            {...this.props}
            changeStep={(s) => this.setState({ step: s })}
            setUser={(user) => this.setState({ user: user })}
            classes={classes}
          />
        ) : null}
        {this.state.step === 1 ? (
          <StepOne
            {...this.props}
            changeStep={(s) => this.setState({ step: s })}
            setUser={(user) => this.setState({ user: user })}
            user={this.state.user}
            setHandle={(handle) => this.setState({ handle: handle })}
            classes={classes}
          />
        ) : null}
        {this.state.step === 2 ? (
          <StepTwo
            {...this.props}
            changeStep={(s) => this.setState({ step: s })}
            setUser={(user) => this.setState({ user: user })}
            user={this.state.user}
            handle={this.state.handle}
            classes={classes}
          />
        ) : null}
        {this.state.step === 3 ? (
          <StepThree
            {...this.props}
            changeStep={(s) => this.setState({ step: s })}
            user={this.state.user}
            id={this.state.id}
            handle={this.state.handle}
            classes={classes}
          />
        ) : null}
        <script src="js/load-image.all.min.js"></script>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles, { withTheme: true })(Register));
