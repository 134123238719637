import get from "lodash.get";
import {
  flureeFetch,
  parseJSON,
  // getToken
} from "../flureeFetch";
import { serverURL } from "../appConfig";
import { trackPromise } from "react-promise-tracker";
import constants from "./constants";
const { DEFAULT_CONTEXT, PROPERTY_MAP, CLASS_TYPES } = constants;

export const formatNumber = (num) => {
  let roundedNum = num % 1 === 0 ? num : num.toFixed(2);
  return roundedNum.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const deformatNumber = (string) => Number(string.replace(/,/g, ""));

export const queryAdsForUser = (cashtag, token) =>
  Promise.all([
    flureeFetch("/query", {
      "@context": {
        ...DEFAULT_CONTEXT,
        ...PROPERTY_MAP,
        userAds: { "@reverse": PROPERTY_MAP.user },
        consumerData: { "@container": "@list" },
      },
      where: {
        id: "?s",
        type: CLASS_TYPES.engagement,
        campaign: {
          id: "?x",
        },
        user: {
          cashtag,
        },
      },
      select: {
        "?s": ["*"],
      },
      depth: 3,
    }),
    flureeFetch("/query", {
      "@context": {
        ...DEFAULT_CONTEXT,
        ...PROPERTY_MAP,
      },
      where: {
        id: "?s",
        type: CLASS_TYPES.campaign,
      },
      select: {
        "?s": ["*"],
      },
      depth: 3,
    }),
  ])
    // flureeFetch(
    //   "multi-query",
    //   {
    //     // "userAds": {
    //     //     "select": ["?watched", "?shared", "?ad", { "?ad": ["*", { "company": ["*"] }] }],
    //     //     "where": [["?user", "user/handle", handle],
    //     //     ["?user", "user/shoppingInterests", "?interest"],
    //     //     ["?ad", "ad/categories", "?interest"],
    //     //     ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }],
    //     //     ["?user", "user/userAds", "?userAd"],
    //     //     ["?userAd", "userAd/ad", "?ad"],
    //     //     ["?userAd", "userAd/watched", "?watched",  {"optional": true}],
    //     //     ["?userAd", "userAd/sharedOnTwitter", "?shared", { "optional": true }]],
    //     //     "orderBy": ["ASC", "?ad"]
    //     // },

    //     userAds: {
    //       select: {
    //         "?userAd": [
    //           "*",
    //           { questionsSucceeded: ["*"] },
    //           { questionFailed: ["*"] },
    //           { consumerData: ["*"] },
    //         ],
    //       },
    //       where: [
    //         ["?user", "user/handle", handle],
    //         ["?user", "user/userAds", "?userAd"],
    //       ],
    //     },
    //     allAds: {
    //       select: { "?ad": ["*", { company: ["*"] }, { questions: ["*"] }] },
    //       where: [
    //         ["?user", "user/handle", handle],
    //         ["?user", "user/shoppingInterests", "?interest"],
    //         ["?ad", "ad/categories", "?interest"],
    //         ["?ad", "ad/finished", "?finished"],
    //       ],
    //       filter: ["(and ?finished)"],
    //     },

    //     // "select": ["?ad", { "?ad": ["*", { "company": ["*"] }] }, "?userAd"], //"?questionFailed", "?questionsSucceeded", "?watched", "?shared",
    //     // "where": [["?user", "user/handle", handle],
    //     // ["?user", "user/shoppingInterests", "?interest"],
    //     // ["?ad", "ad/categories", "?interest"],
    //     // ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }],
    //     // ["?user", "user/userAds", "?userAd", { "optional": true }],
    //     // ["?userAd", "userAd/ad", "?ad", { "optional": true }]
    //     // ["?userAd", "userAd/watched", "?watched", { "optional": true }],
    //     // ["?userAd", "userAd/questionFailed", "?questionFailed", { "optional": true }],
    //     // ["?userAd", "userAd/questionsSucceeded", "?questionsSucceeded", { "optional": true }],
    //     // ["?userAd", "userAd/sharedOnTwitter", "?shared", { "optional": true }]]
    //     // "allAds": {
    //     //     "select": ["?ad", { "?ad": ["*", { "company": ["*"] }] }],
    //     //     "where": [["?user", "user/handle", handle],
    //     //     ["?user", "user/shoppingInterests", "?interest"],
    //     //     ["?ad", "ad/categories", "?interest"],
    //     //     ["?ad", "ad/finished", "?finished", { "filter": "(and ?finished)" }]]
    //     // }
    //   },
    //   token
    // )
    .then((res) => {
      let requestAds = [];
      let watchedAds = [];
      let completedAds = [];

      // let userAds = get(res, "userAds");
      // let allAds = get(res, "allAds");
      const [userAds, allAds] = res;
      // const adIdObj = {}
      // allAds = allAds.filter(ad => {
      //     if(adIdObj[ad[2]]) {
      //         return false
      //     } else {
      //         adIdObj[ad[2]] = true
      //         return true
      //     }
      // })
      // debugger;
      // userAds.map(userAds => {
      //     let watched = userAds[0];
      //     if (watched) {
      //         watchedAds.push(userAds[1])
      //     } else {
      //         requestAds.push(userAds[2])
      //     }
      // })
      allAds.map((ad) => {
        const userAd = userAds.find((userAd) => userAd.campaign.id === ad.id);
        if (userAd) {
          ad["userAd"] = userAd;
          watchedAds.push(ad);
        } else {
          requestAds.push(ad);
        }
      });
      // requestAds.push(allAds[0][1]) for now, allow users to repeatedly access Fabric ad

      // Order by id
      requestAds = requestAds.sort(function (a, b) {
        let aId = a.id;
        let bId = b.id;
        return aId - bId;
      });
      watchedAds = watchedAds.sort(function (a, b) {
        let aId = a.id;
        let bId = b.id;
        return aId - bId;
      });

      // let totalPotentialPoints = requestAds.reduce((acc, value) => {
      //     let questionPoints = get(value[3], "ad/questionPoints");
      //     let videoPoints = get(value[3], "ad/videoPoints");
      //     let postPoints = get(value[3], "ad/postPoints") || 0
      //     return acc + questionPoints + videoPoints + postPoints
      // }, 0);
      return { requestAds, watchedAds };
    });

export const reachedThreshold = (earnings, points, threshold) => {
  const newEarnings = earnings + points;
  return newEarnings >= threshold && newEarnings - points < threshold;
};

export const requestCard = (user, token) => {
  return trackPromise(
    new Promise((resolve, reject) => {
      return (
        fetch(`${serverURL}galileo-notify`, {
          method: "POST",
          headers: { "Content-type": "application/json", authorization: `Bearer ${token}` },
          body: JSON.stringify({
            ...user,
            //  token
          }),
        })
          // })
          .then((res) => {
            if (res.status === 200) {
              return resolve(res.status);
            } else {
              return reject(res);
            }
          })
          .catch((err) => reject(err))
      );
    })
  );
  // getToken()
  //   .then((token) => {
  //     return fetch(`${serverURL}galileo-notify`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ ...user, token }),
  //     });
  //   })
  //   .then((res) => {
  //     console.log(res);
  //     return res.status;
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     debugger;
  // });
};

export const getWalletStats = (walletName, blockOneDate, dayAgoDate, weekAgoDate, monthAgoDate) => {
  // TODO: FIX ALL THIS
  return new Promise((resolve, reject) => {
    resolve({
      dayEarnings: 0,
      weekEarnings: 0,
      monthEarnings: 0,
    });
  });
  const userToken = window.localStorage.getItem("stringBean");
  const dayFlag = blockOneDate < dayAgoDate;
  const weekFlag = blockOneDate < weekAgoDate;
  const monthFlag = blockOneDate < monthAgoDate;
  const selectOne = ["?earnings", "?balance"];
  const where = [
    ["?wallet", "wallet/name", walletName],
    ["?wallet", "wallet/earnings", "?earnings"],
    ["?wallet", "wallet/balance", "?balance"],
  ];
  const firstWalletDate = new Date(blockOneDate + 1000000).toISOString();
  const optional = [];
  if (dayFlag) {
    selectOne.push("?earningsYesterday");
    optional.push([
      "$fdbP1D",
      "?wallet",
      "wallet/earnings",
      "?earningsYesterday",
      // { optional: true }
    ]);
  } else {
    selectOne.push("?earningsYesterday");
    optional.push([
      "$fdb" + firstWalletDate,
      "?wallet",
      "wallet/earnings",
      "?earningsYesterday",
      // { optional: true }
    ]);
  }
  if (weekFlag) {
    selectOne.push("?earningsLastWeek");
    optional.push([
      "$fdbP1W",
      "?wallet",
      "wallet/earnings",
      "?earningsLastWeek",
      // { optional: true }
    ]);
  } else {
    selectOne.push("?earningsLastWeek");
    optional.push([
      "$fdb" + firstWalletDate,
      "?wallet",
      "wallet/earnings",
      "?earningsLastWeek",
      // { optional: true }
    ]);
  }
  if (monthFlag) {
    selectOne.push("?earningsLastMonth");
    optional.push([
      "$fdbP1M",
      "?wallet",
      "wallet/earnings",
      "?earningsLastMonth",
      // { optional: true }
    ]);
  } else {
    selectOne.push("?earningsLastMonth");
    optional.push([
      "$fdb" + firstWalletDate,
      "?wallet",
      "wallet/earnings",
      "?earningsLastMonth",
      // { optional: true }
    ]);
  }
  if (optional.length > 0) where.push({ optional });
  const walletQuery = {
    selectOne: selectOne,
    where: where,
  };
  debugger;
  return flureeFetch("query", walletQuery, userToken)
    .then((res) => {
      debugger;
      if (res) {
        const [earnings, earningsYesterday, earningsLastWeek, earningsLastMonth] = res;
        return {
          dayEarnings: earnings - (earningsYesterday || 0),
          weekEarnings: earnings - (earningsLastWeek || 0),
          monthEarnings: earnings - (earningsLastMonth || 0),
        };
        // setDayEarnings(earnings - (earningsYesterday || 0));
        // setWeekEarnings(earnings - (earningsLastWeek || 0));
        // setMonthEarnings(earnings - (earningsLastMonth || 0));
        // setBalance(balance);
      } else {
        // setDayEarnings(walletEarnings)
        // setWeekEarnings(walletEarnings)
        // setMonthEarnings(walletEarnings)
      }
    })
    .catch((err) => {
      debugger;
    });
};

export const checkLandscape = () => {
  const isMobile = () => document.querySelector("body").getAttribute("class") === "mobile";

  if (window.screen.orientation) {
    const { type } = window.screen.orientation;
    // console.log(window.screen);
    // console.log({ type, mobile: isMobile() });
    return type === "landscape-primary" && isMobile() === true;
  } else {
    // console.log(window.screen.height < window.screen.width && isMobile() === true)
    return window.screen.width > window.screen.height && isMobile() === true;
  }
};
