import React from 'react';
import { createBrowserHistory } from 'history';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { UserProvider } from '../contexts/UserContext';
import LandingPage from '../screens/LandingPage';
import Register from './Register';
import Login from './Login';
import PasswordReset from './PasswordReset';
import PasswordChange from './PasswordChange';
import Help from './Help';
import LoadingSpinner from './LoadingSpinner';

const drawerWidth = 350;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    // display: "flex",
    // flexDirection: 'column',
    // justifyContent: "center",
    // padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      // minHeight: "auto",
      // padding: 10
    },
    '@media screen and orientation(landscape)': {
      width: '80%'
    }
  },
  main: {
    display: 'flex',
    height: '100%'
  },
  toolbar: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
    //height: "100%"
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'relative',
    //borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
    borderRight: 0,
    height: '100%'
  },
  drawerDocked: {
    height: '100%'
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: 'auto'
    }
  },
  aside: {
    height: '100%'
  }
}));

function Unauthenticated(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { refreshUser } = props;
  const history = createBrowserHistory();
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  return (
    <UserProvider
      value={{
        user: props.user,
        refreshUser: props.refreshUser
      }}
    >
      <LoadingSpinner />

      <CssBaseline />
      <Container className={classes.root} disableGutters>
        <BrowserRouter>
          <Switch>
            <Route
              path="/register"
              render={(props) => (
                <>
                  <Register {...props} refreshUser={refreshUser} />
                </>
              )}
            />
            <Route
              path="/login"
              render={(props) => (
                <>
                  <Login {...props} refreshUser={refreshUser} />
                </>
              )}
            />
            <Route
              path="/reset/:handle/:token"
              render={(props) => (
                <>
                  <PasswordChange
                    {...props}
                    handle={props.match.params.handle}
                    token={props.match.params.token}
                    refreshUser={refreshUser}
                  />
                </>
              )}
            />
            <Route
              path="/reset"
              render={(props) => (
                <>
                  <PasswordReset {...props} refreshUser={refreshUser} />
                </>
              )}
            />
            <Route
              path="/help"
              render={(props) => (
                <>
                  <Help {...props} {...props} refreshUser={refreshUser} />
                </>
              )}
            />
            <Route
              path="/"
              render={(props) => (
                <>
                  <LandingPage {...props} refreshUser={refreshUser} />
                </>
              )}
            />
          </Switch>
        </BrowserRouter>
      </Container>
    </UserProvider>
  );
}

export default Unauthenticated;
