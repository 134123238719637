const DEFAULT_CONTEXT = {
  id: "@id",
  type: "@type",
  fabric: "http://fabricpay.io/ns/",
  schema: "http://schema.org/",
  f: "https://ns.flur.ee/ledger#",
  xsd: "http://www.w3.org/2001/XMLSchema#",
  rdfs: "http://www.w3.org/2000/01/rdf-schema#",
  rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
  sh: "http://www.w3.org/ns/shacl#",
};

const PROPERTY_CONTEXT = {
  email: "schema:email",
  password: "fabric:password",
  handle: "fabric:cashtag",
  firstName: "schema:givenName",
  lastName: "schema:familyName",
  cellPhone: "schema:telephone",
  zipcode: "schema:postalCode",
  dateOfBirth: "schema:birthDate",
  gender: "schema:gender",
  profilePic: "schema:image",
  wallet: "fabric:wallet",
  earnings: "fabric:totalEarnings",
  balance: "fabric:balance",
  applied: "fabric:applied",
  googleId: "fabric:googleId",
  name: "schema:name",
};

const PAYOUT_MULTIPLIER = 1;

const MIN_CAMPAIGN_BUDGET_IN_USD = 1;

const DEFAULT_DISPLAY_COUNT = 10;

const NOTIFICATION_TYPES = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  WARNING: "WARNING",
};

const MAX_QUESTION_COUNT = 3;

const MAX_MULTI_CHOICE_COUNT = 4;

const STRIPE_LIMIT = 10;

const LOW_ACCOUNT_BALANCE_IN_PENNIES = 5000;

const LOW_CAMPAIGN_BUDGET_IN_PENNIES = 5000;

const CLASS_TYPES = {
  advertiser: "fabric:Advertiser",
  answer: "fabric:Answer", // added this
  campaign: "fabric:Campaign",
  company: "fabric:Company",
  engagement: "fabric:Engagement", // added this
  postalAddress: "schema:PostalAddress",
  question: "fabric:Question",
  response: "fabric:Response",
  user: "fabric:User",
  wallet: "fabric:Wallet",
};

const PROPERTY_MAP = {
  id: "@id",
  actionText: "fabric:actionText",
  accountBalance: "fabric:accountBalance",
  actionURI: "fabric:actionURI",
  address: "schema:address",
  adSeen: "fabric:adSeen",
  adWatched: "fabric:adWatched",
  answerChoice: "fabric:answerChoice",
  answerChoices: "fabric:answerChoices",
  answerPoints: "fabric:answerPoints",
  answerText: "fabric:answerText",
  applied: "fabric:applied",
  author: "schema:author",
  balance: "fabric:balance",
  birthDate: "schema:birthDate",
  budgetSpent: "fabric:budgetSpent",
  budgetRemaining: "fabric:budgetRemaining",
  campaign: "fabric:campaign",
  cashEarned: "fabric:cashEarned",
  cashtag: "fabric:cashtag",
  cellPhone: "schema:telephone",
  clickPoints: "fabric:clickPoints",
  contentUrl: "schema:contentUrl",
  correctAnswer: "fabric:correctAnswer",
  country: "fabric:country",
  countryCode: "fabric:countryCode",
  dateOfBirth: "schema:birthDate",
  datePublished: "schema:datePublished",
  description: "schema:description",
  disabled: "fabric:disabled",
  earnings: "fabric:totalEarnings",
  email: "schema:email",
  emailConfirmed: "fabric:emailConfirmed",
  employee: "schema:employee",
  familyName: "schema:familyName",
  firstName: "schema:givenName",
  goal: "fabric:goal",
  gender: "schema:gender",
  givenName: "schema:givenName",
  handle: "fabric:cashtag",
  highDisplay: "fabric:highDisplay",
  image: "schema:image",
  industry: "fabric:industry",
  lastName: "schema:familyName",
  lowDisplay: "fabric:lowDisplay",
  logo: "schema:logo",
  name: "schema:name",
  otp: "fabric:otp",
  password: "fabric:password",
  pointsReceived: "fabric:pointsReceived",
  payout: "fabric:payout",
  postalCode: "schema:postalCode",
  profilePic: "schema:image",
  question: "fabric:question",
  questionFailed: "fabric:questionFailed",
  questions: "fabric:questions",
  questionText: "fabric:questionText",
  questionType: "fabric:questionType",
  responses: "fabric:responses",
  sharePoints: "fabric:sharePoints",
  state: "fabric:state",
  stepCount: "fabric:stepCount",
  stripeId: "fabric:stripeId",
  subtitle: "fabric:subtitle",
  telephone: "schema:telephone",
  thumbnailUrl: "schema:thumbnailUrl",
  timezone: "fabric:timezone",
  title: "fabric:title",
  totalEarnings: "fabric:totalEarnings",
  type: "@type",
  user: "fabric:user",
  userAds: "fabric:userAds",
  wallet: "fabric:wallet",
  watchPoints: "fabric:watchPoints",
  zipcode: "schema:postalCode",
};

const GENDERS = [
  { label: "Prefer not to answer", value: "Prefer not to answer" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const QUESTION_TYPES = [
  { label: "Test", value: "Test" },
  { label: "Range", value: "Range" },
  { label: "Survey", value: "Survey" },
  { label: "Yes/No", value: "Yes/No" },
  { label: "Call to Action", value: "Call to Action" },
];

const INDUSTRIES = [
  { label: "Agriculture and Farming", value: "Agriculture and Farming" },
  { label: "Automotive", value: "Automotive" },
  { label: "Banking and Finance", value: "Banking and Finance" },
  {
    label: "Construction and Real Estate",
    value: "Construction and Real Estate",
  },
  { label: "Education", value: "Education" },
  { label: "Energy and Utilities", value: "Energy and Utilities" },
  { label: "Entertainment and Media", value: "Entertainment and Media" },
  { label: "Food and Beverage", value: "Food and Beverage" },
  {
    label: "Healthcare and Pharmaceuticals",
    value: "Healthcare and Pharmaceuticals",
  },
  { label: "Information Technology", value: "Information Technology" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Nonprofit and Philanthropy", value: "Nonprofit and Philanthropy" },
  { label: "Retail", value: "Retail" },
  { label: "Telecommunications", value: "Telecommunications" },
  {
    label: "Transportation and Logistics",
    value: "Transportation and Logistics",
  },
  { label: "Travel and Tourism", value: "Travel and Tourism" },
  { label: "E-commerce", value: "E-commerce" },
  { label: "Environmental Services", value: "Environmental Services" },
  { label: "Legal Services", value: "Legal Services" },
  { label: "Marketing and Advertising", value: "Marketing and Advertising" },
  { label: "Professional Services", value: "Professional Services" },
  {
    label: "Government and Public Administration",
    value: "Government and Public Administration",
  },
  { label: "Hospitality and Tourism", value: "Hospitality and Tourism" },
  { label: "Sports and Recreation", value: "Sports and Recreation" },
  { label: "Fashion and Apparel", value: "Fashion and Apparel" },
  { label: "Aerospace and Defense", value: "Aerospace and Defense" },
  {
    label: "Mining and Natural Resources",
    value: "Mining and Natural Resources",
  },
  { label: "Biotechnology", value: "Biotechnology" },
  { label: "Insurance", value: "Insurance" },
  { label: "Architecture and Design", value: "Architecture and Design" },
];

const TIMEZONES = [
  {
    label: "(UTC-12:00) International Date Line West",
    value: "(UTC-12:00) International Date Line West",
  },
  {
    label: "(UTC-11:00) Coordinated Universal Time-11",
    value: "(UTC-11:00) Coordinated Universal Time-11",
  },
  { label: "(UTC-10:00) Hawaii", value: "(UTC-10:00) Hawaii" },
  { label: "(UTC-09:00) Alaska", value: "(UTC-09:00) Alaska" },
  {
    label: "(UTC-08:00) Pacific Time (US & Canada)",
    value: "(UTC-08:00) Pacific Time (US & Canada)",
  },
  {
    label: "(UTC-07:00) Mountain Time (US & Canada)",
    value: "(UTC-07:00) Mountain Time (US & Canada)",
  },
  {
    label: "(UTC-06:00) Central Time (US & Canada)",
    value: "(UTC-06:00) Central Time (US & Canada)",
  },
  {
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    value: "(UTC-05:00) Eastern Time (US & Canada)",
  },
  {
    label: "(UTC-04:00) Atlantic Time (Canada)",
    value: "(UTC-04:00) Atlantic Time (Canada)",
  },
  { label: "(UTC-03:30) Newfoundland", value: "(UTC-03:30) Newfoundland" },
  { label: "(UTC-03:00) Buenos Aires", value: "(UTC-03:00) Buenos Aires" },
  { label: "(UTC-02:00) Mid-Atlantic", value: "(UTC-02:00) Mid-Atlantic" },
  { label: "(UTC-01:00) Azores", value: "(UTC-01:00) Azores" },
  {
    label: "(UTC+00:00) Coordinated Universal Time",
    value: "(UTC+00:00) Coordinated Universal Time",
  },
  { label: "(UTC+01:00) Brussels", value: "(UTC+01:00) Brussels" },
  { label: "(UTC+02:00) Kaliningrad", value: "(UTC+02:00) Kaliningrad" },
  { label: "(UTC+03:00) Moscow", value: "(UTC+03:00) Moscow" },
  { label: "(UTC+03:30) Tehran", value: "(UTC+03:30) Tehran" },
  { label: "(UTC+04:00) Abu Dhabi", value: "(UTC+04:00) Abu Dhabi" },
  { label: "(UTC+04:30) Kabul", value: "(UTC+04:30) Kabul" },
  { label: "(UTC+05:00) Islamabad", value: "(UTC+05:00) Islamabad" },
  { label: "(UTC+05:30) Chennai", value: "(UTC+05:30) Chennai" },
  { label: "(UTC+05:45) Kathmandu", value: "(UTC+05:45) Kathmandu" },
  { label: "(UTC+06:00) Astana", value: "(UTC+06:00) Astana" },
  { label: "(UTC+06:30) Yangon", value: "(UTC+06:30) Yangon" },
  { label: "(UTC+07:00) Bangkok", value: "(UTC+07:00) Bangkok" },
  { label: "(UTC+08:00) Kuala Lumpur", value: "(UTC+08:00) Kuala Lumpur" },
  { label: "(UTC+09:00) Tokyo", value: "(UTC+09:00) Tokyo" },
  { label: "(UTC+09:30) Adelaide", value: "(UTC+09:30) Adelaide" },
  { label: "(UTC+10:00) Sydney", value: "(UTC+10:00) Sydney" },
  { label: "(UTC+11:00) Solomon Is.", value: "(UTC+11:00) Solomon Is." },
  { label: "(UTC+12:00) Fiji", value: "(UTC+12:00) Fiji" },
  { label: "(UTC+13:00) Tonga", value: "(UTC+13:00) Tonga" },
  { label: "(UTC+14:00) Kiritimati", value: "(UTC+14:00) Kiritimati" },
];

const US_STATES = [
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "New York" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];

const TOOLTIPS = {
  impressions:
    "Total audience exposure to an ad - reflects the number of times an ad has been viewed, providing advertisers a comprehensive metric of reach and visibility",
  fullViews:
    "Indicates the count of users who have watched an entire ad from start to finish, providing insights into the audience's level of engagement and interest in the content",
  engagements:
    "Measures user participation beyond observation - the number of questions answered post-advertisement, adding a layer of interactive depth to the viewing experience",
  qualifiedLeads:
    "Represents the number of users who have expressed interest in a product or service by opting to be contacted. These are potential customers who have indicated a willingness to learn more about the advertised offering",
  reach:
    "Maximize exposure by displaying your ad to a large audience. Reach campaigns focus on tracking ad views, emphasizing the number of people who clicked on the ad without detailed engagement metrics. No follow-up engagement questions are included in this campaign type.",
  videoViews:
    "Capture audience attention with compelling video content. Video view campaigns track both clicks on the ad and whether users watch the entire video from start to finish. This campaign type prioritizes video engagement without incorporating follow-up questions.",
  leadGeneration:
    "Drive interaction and gather valuable insights. Lead generation campaigns go beyond clicks and full watches, allowing advertisers to pose follow-up questions to users. This campaign type enables a deeper level of engagement, tracking user interaction and providing an opportunity for advertisers to gather additional information from their audience.",
};

const constants = {
  CLASS_TYPES,
  DEFAULT_DISPLAY_COUNT,
  GENDERS,
  INDUSTRIES,
  LOW_ACCOUNT_BALANCE_IN_PENNIES,
  LOW_CAMPAIGN_BUDGET_IN_PENNIES,
  MAX_MULTI_CHOICE_COUNT,
  MAX_QUESTION_COUNT,
  MIN_CAMPAIGN_BUDGET_IN_USD,
  NOTIFICATION_TYPES,
  PAYOUT_MULTIPLIER,
  PROPERTY_MAP,
  QUESTION_TYPES,
  STRIPE_LIMIT,
  TIMEZONES,
  TOOLTIPS,
  US_STATES,
  DEFAULT_CONTEXT,
  PROPERTY_CONTEXT,
};

export default constants;
